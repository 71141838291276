import { it } from 'date-fns/locale';
import React, { memo, useEffect, useState } from 'react';
import { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { isMobile } from "react-device-detect";
import Switch from 'react-switch';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import deviceHelper from '../../../helpers/device';
import * as ResellerAPI from '../../../services/reseller';
import checkFiscalCode from '../../../validators/fiscalCodeValidator';
import checkVatCode from '../../../validators/vatCodeValidator';
import Divider from '../../Common/Divider/Divider';

const RtActivationFormData  = (props) => {
    const [formData, setFormData] = useState({
		enabledDevice: props.data?.enabledDevice ?? ( props.data?.lab_fiscal_code !== "" && props.data?.lab_vatcode !== "" ? true : false),
        lab_vatcode : props.data?.lab_vatcode ?? "",
        lab_vatcode_feedback: "",
        lab_fiscal_code: props.data?.lab_fiscal_code ?? "",
        lab_fiscal_code_feedback: "",
        activation_date: props.data?.activation_date ?  new Date(props.data.activation_date): "",
        activation_date_feedback: "",
        validation_status: {
            lab_vatcode : false,
            lab_fiscal_code : false,
            activation_date : true //optional,
        },
        activity_vatcode : props.activityVatCode ?? props.data?.activity_vatcode,
        device_type: props.data?.device_type ?? "1",
    });

	// const [enabledDevice, setEnabledDevice] = useState(false);
	const [autoCompleteLabFiscalCode, setAutoCompleteLabFiscalCode] = useState(props.data?.lab_fiscal_code ?? "")
	const [autoCompleteLabVatCode, setAutoCompleteLabVatCode] = useState(props.data?.lab_vatcode ?? "")

    useEffect(() => {
        registerLocale('it', it);
        getResellerData();
    }, [])

    useEffect(() => {
        if(props.matricolaAutoConfig && props.matricolaAutoConfig.length > 5){
            const code = props.matricolaAutoConfig.substring(0,5).toUpperCase();
            if(code === "94ISM"){
                setFormData((prevState) => ({
                    ...prevState,
                    device_type: "2"
                }))
            }
        }

    },[props.matricolaAutoConfig])


    useEffect(() => {
        validateLabVatCode(formData.lab_vatcode);
        validateLabFiscalCode(formData.lab_fiscal_code);
    },[formData.lab_vatcode, formData.lab_fiscal_code])

    useEffect(() => {
        isFormValid();
    },[formData])

    const getResellerDetails = async () => {
        const details = await ResellerAPI.get();
        if(details.data && details.data.length > 0){
            setFormData((prevState) => ({
                ...prevState,
                lab_vatcode: !prevState.lab_vatcode ? details.data[0].vatCode : prevState.lab_vatcode,
                lab_fiscal_code: !prevState.lab_fiscal_code ? details.data[0].fiscalCode : prevState.lab_fiscal_code,
            }))

        }
    }

    const getResellerData = async () => {
        await getResellerDetails();
    }

    const handleChangeLabVatCode = (value) => {
        setFormData((prevState) => ({
            ...prevState,
            lab_vatcode: value
        }))
    }

    const handleChangeLabFiscalCode = (value) => {
        setFormData((prevState) => ({
            ...prevState,
            lab_fiscal_code: value
        }))
    }

    const handleChangeActivationDate = (value) => {
        setFormData((prevState) => ({
            ...prevState,
            activation_date: value
        }))
    }

	const handleChangeEnabledDevice = (value) => {
		if(value) {
			handleChangeLabVatCode(autoCompleteLabVatCode);
			handleChangeLabFiscalCode(autoCompleteLabFiscalCode);
		}
		else {
			setAutoCompleteLabVatCode(props.data?.lab_vatcode);
			setAutoCompleteLabFiscalCode(props.data?.lab_fiscal_code);
			handleChangeLabVatCode("");
			handleChangeLabFiscalCode("");
		}

        setFormData((prevState) => ({
            ...prevState,
            enabledDevice: value
        }))
	}

    const validateLabVatCode = (vatCode) => {
        const valueLength = vatCode.length;
        let vatCodeFeedback = "";
        let status = formData.enabledDevice ? false : true;

		if(formData.enabledDevice) {
			if(valueLength === 0){
				status = false;
				vatCodeFeedback = "La partita IVA è obbligatoria";
			}
			else if(valueLength !== 11){
				status = false;
				vatCodeFeedback = "La partita IVA deve contenere 11 caratteri";
			}
			else {
				//Validate vat code with an algorithm
				let isVatCodeValid = checkVatCode(vatCode);
				status = isVatCodeValid ? true : false;
				vatCodeFeedback = isVatCodeValid ? "" : "La partita IVA non è valida";
			}
		}

        setFormData((prevState) => ({
            ...prevState,
            vatCodeFeedback: vatCodeFeedback,
            validation_status: {...prevState.validation_status, lab_vatcode: status}
        }))
    }

    const validateLabFiscalCode = (fiscalCode) => {
        const valueLength       = fiscalCode.length;
        let isFiscalCodeValid   = formData.enabledDevice ? false : true;
        let fiscalCodeFeedback  = formData.enabledDevice ? "Il codice fiscale è obbligatorio" : "";

		if(formData.enabledDevice) {
			if(valueLength === 0){
				isFiscalCodeValid   = false;
				fiscalCodeFeedback  = "Il codice fiscale è obbligatorio";
			}
			else if(valueLength !== 11 && valueLength !== 16){
				isFiscalCodeValid   = false;
				fiscalCodeFeedback  = "Il codice fiscale deve contenere 11 o 16 caratteri";
			}
			else if(valueLength === 11){
				isFiscalCodeValid   = checkVatCode(fiscalCode);
				fiscalCodeFeedback  = isFiscalCodeValid ? "" : "Il codice fiscale non è valido";
			}
			else if(valueLength === 16){
				isFiscalCodeValid   = checkFiscalCode(fiscalCode);
				fiscalCodeFeedback  = isFiscalCodeValid ? "" : "Il codice fiscale non è valido";
			}
		}

        setFormData((prevState) => ({
            ...prevState,
            fiscalCodeFeedback: fiscalCodeFeedback,
            validation_status: {...prevState.validation_status, lab_fiscal_code: isFiscalCodeValid }
        }))
    }


    const handleDeviceTypeChange = (input_value)  => {
        setFormData((prevState) => ({
            ...prevState,
            device_type : input_value
        }))
		props.changeDevice(input_value) // To pass value to Edit.jsx
    }

    const isFormValid = () => {
        let validationStatusList = Object.values(formData.validation_status);
        
        let validationStatus = validationStatusList.every((fieldValidationStatus) => {
            return fieldValidationStatus ? true : false
        });
        
        props.isStepValidated(validationStatus, props.tabId);
        if(validationStatus) saveData();
    }

    const saveData = () => {
        let data = {
            lab_vatcode : formData.lab_vatcode,
            lab_fiscal_code : formData.lab_fiscal_code,
            activation_date : formData.activation_date,
            activity_vatcode : formData.activity_vatcode,
            device_type: formData.device_type,
			enabledDevice: formData.enabledDevice
        }
        props.saveRtActivationData(data);
    }

	let deviceTypeOpts = [
		{index: 1, value: "Basiq"},
		{index: 2, value: "smaRT"}
	]
	if(props.matricolaAutoConfig) {
		let allowedDeviceTypeOpt = deviceHelper.fromFiscalNumberToDeviceType(props.matricolaAutoConfig);
		let filtered = deviceTypeOpts.filter( obj => { return obj.index === allowedDeviceTypeOpt });
		deviceTypeOpts = filtered;
	}

    return (
        <React.Fragment>
            <br />
            <h5>Dati Attivazione RT</h5>
            <hr></hr>
			<FormGroup className='.d-inline-flex' style={{flexDirection: "column"}}>
				<Label> Attiva Dispositivo </Label>
				<Switch onChange={handleChangeEnabledDevice} checked={formData.enabledDevice} />
			</FormGroup>

			{formData.enabledDevice && 
				<>
				<FormGroup>
					<div className={isMobile ? 'flex-row' : ''}>
						<Label for="lab_vatcode">Partita IVA Laboratorio</Label>
						<Input 
							invalid={formData.validation_status.lab_vatcode ? false : true } 
							type="text" name="lab_vatcode" id="lab_vatcode" 
							placeholder=""
							value={formData.lab_vatcode} 
							onChange={e =>  handleChangeLabVatCode(e.target.value)} 
						/>
					</div>  
					<FormFeedback>{formData.lab_vatcode_feedback}</FormFeedback>
				</FormGroup>
			
				<FormGroup>
					<div className={isMobile ? 'flex-row' : ''}>
						<Label for="lab_fiscal_code">Codice Fiscale Tenico Laboratorio</Label>
						<Input 
							invalid={formData.validation_status.lab_fiscal_code ? false : true } 
							type="text" name="lab_fiscal_code" id="lab_fiscal_code" placeholder="" 
							onChange={e => handleChangeLabFiscalCode(e.target.value)} 
							value={formData.lab_fiscal_code} />
					</div>   
					<FormFeedback>{formData.lab_fiscal_code_feedback}</FormFeedback>
				</FormGroup>
				</>
			}
			<Divider />
            <FormGroup>
                <div className={isMobile ? 'flex-row' : ''}>
                    <Label for="activity_vatcode">Partita IVA</Label>
                    <Input key={2} disabled={true} name="activity_vatcode" id="activity_vatcode" type="text" value={ props.activityVatCode ?? formData.activity_vatcode} />
                </div> 
            </FormGroup>
            <FormGroup>
                <div className={isMobile ? 'flex-row' : ''}>
                    <Label> Tipo dispositivo</Label>
                    <Input
                        value={formData.device_type}
                        type="select"
                        name="deviceType"
                        id="idDeviceType"
                        onChange={ e => handleDeviceTypeChange( e.target.value)}
                        disabled={props.isFromRTModal}
                        >
                            {
                                deviceTypeOpts.map((opt) => 
                                    <option key={opt.index} value={opt.index}>{opt.value}</option>
                                )
                            }
                        </Input>
                </div>  
            </FormGroup>
            {/* <FormGroup>
                <div className={isMobile ? 'flex-row' : ''}>
                    <Label for="activation_date">Data messa in servizio</Label>
                    <DatePicker locale={it} name="activation_date" id="activation_date" className="form-control" 
                        selected={formData.activation_date} onChange={handleChangeActivationDate} dateFormat="yyyy-MM-dd" isClearable/>
                </div> 
                <FormFeedback>{formData.activation_date_feedback }</FormFeedback> 
            </FormGroup> */}
        </React.Fragment>
    )
    
}

export default memo(RtActivationFormData);