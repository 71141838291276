import React, { useEffect, useState } from 'react';
import { 
    Modal, 
    ModalHeader,
    ModalBody,
    Row,
    Col,
    Button,
    FormGroup,
    Label,
    Input
} from 'reactstrap';
import { getLinkReseller } from '../../../services/devices';
import { Alert } from 'reactstrap';
import { getFavouriteConfig } from '../../../services/configuration';
import { useNavigate } from 'react-router-dom';

const AutoConfigRtModal = (props) => {

    const [errorAlert, setErrorAlert] = useState({alreadyLinkedOpen: false, favouriteModels: false,  message: ''});
    const [modelConfiguration, setModelConfiguration] = useState(false);
    const [models, setModels] = useState([]);
    const [modelSelected, setModelSelected] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        if(props.matricola){
            const matricola = props.matricola;
            getResellerLink(matricola)
        }
    },[props.matricola]);

    useEffect(() => {
        if(modelConfiguration){
            getFavouriteConfiguration()
        }
    },[modelConfiguration]);

    const getResellerLink = async (matricola) => {
        try {
            const responseLinkReseller = await getLinkReseller(matricola);
            if(responseLinkReseller?.data.status === false ){
                setErrorAlert((prevState) => ({
                    ...prevState,
                    alreadyLinkedOpen: true,
                    message: responseLinkReseller?.data.message
                }))
            }
            console.log(responseLinkReseller);
        } catch (error) {
            setErrorAlert((prevState) => ({
                ...prevState,
                alreadyLinkedOpen: true,
                message: 'Reseller link call in error'
            }))
            console.log(error);
        }
    }

    const getFavouriteConfiguration = async () => {
        try {
            const favouriteConfigResponse = await getFavouriteConfig();
            if(favouriteConfigResponse?.data?.data.length > 0){
                setModels(favouriteConfigResponse.data.data);
            }else{
                setModels([]);
            }
            console.log(favouriteConfigResponse);
        } catch (error) {
            setErrorAlert((prevState) => ({
                ...prevState,
                favouriteModels: true,
                message: 'Favourite config call in error'
            }))
            console.log(error);
        }
    }

    const onAlertDismiss = () => {
        setErrorAlert((prevState) => ({
            ...prevState,
            alreadyLinkedOpen: false,
            message: ''
        }))
    }

    const onAlertModelsDismiss = () => {
        setErrorAlert((prevState) => ({
            ...prevState,
            favouriteModels: false,
            message: ''
        }))
    }

    const createNewConfiguration = () => {
        props.toggleAutoConfigRtModal();
        navigate("/home/configuration", { state: { from: "new_config", deviceId: props.matricola } } );
    }

    const createFromModelConfiguration = () => {
        setModelConfiguration(!modelConfiguration);
    }

    const confirmFromModelConfiguration = () => {
        props.toggleAutoConfigRtModal();
        navigate("/home/configuration", { state: { from: "new_config_from_model", configId: modelSelected, deviceId: props.matricola } } );
    }

    const handleModelChange = (event) => {
        setModelSelected(event.target.value);
    }

    return (
        <Modal id="edit-devices-modal" isOpen={props.isOpen} size="xl">
            <ModalHeader toggle={props.toggleAutoConfigRtModal}>
                {props.matricola}
            </ModalHeader>
            <ModalBody>
                <Alert color="danger" isOpen={errorAlert.alreadyLinkedOpen} toggle={onAlertDismiss}>{ errorAlert.message }</Alert>
                <Alert color="danger" isOpen={errorAlert.favouriteModels} toggle={onAlertModelsDismiss}>{ errorAlert.message }</Alert>
                {!errorAlert.alreadyLinkedOpen ? 
                    <Row>
						<Col className="mt-2 text-center" md={6} sm={12}>
							<Button className="w-100 btn-lg" color="info" onClick={createNewConfiguration}>
								Crea una nuova configurazione
							</Button>
						</Col>
						<Col className="mt-2 text-center" md={6} sm={12}>
							<Button className="w-100 btn-lg" color="warning" onClick={createFromModelConfiguration} disabled={errorAlert.favouriteModels}>
								Crea una configurazione da modello
							</Button>
						</Col>
                    </Row>
                 : null}
                {modelConfiguration && models.length > 0 ?
                    <>
                        <Row style={{marginTop: 20}}>
                            <Col>
                                <FormGroup>
                                    <Label>Modelli di configurazione</Label>
                                    
                                    <Input
                                        value={modelSelected}
                                        type="select"
                                        name="configurationModels"
                                        id="idConfigurationModels"
                                        onChange={handleModelChange}
                                    >
                                        <option value={""}></option>
                                        {models.map((model) => {
                                            return (<option key={model.id} value={model.id}>{model.activity_name}</option>)
                                        })}

                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button
                                    style={{float:'right'}}
                                    color='success'
                                    onClick={confirmFromModelConfiguration}
                                    disabled={!modelSelected}
                                >
                                Conferma
                                </Button>
                            </Col>
                        </Row> 
                    </>
                : null}

                <hr/>
                <Row>
                    <Col>
                        <Button
                            style={{float:'right'}}
                            color='success'
                            onClick={props.toggleAutoConfigRtModal}
                        >
                            Esci
                        </Button>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    )
    
}

export default AutoConfigRtModal;
