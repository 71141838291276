import React, { Component } from 'react'

export default class EmbeddedGoogleMap extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
             
        }
    }
    
    render() {
        return (
            <div className="mapouter">
                <div className="gmap_canvas">
                    <iframe title="Embedded Map" width={ this.props.width || "100%"} height={ this.props.height || 500 }
                        id="gmap_canvas" 
                        src={`https://maps.google.com/maps?q=${this.props.address + " " + this.props.zip_code}&t=&z=${ this.props.zoom || 17}&ie=UTF8&iwloc=&output=embed`}
                        frameBorder="0" 
                        scrolling="no" 
                        marginHeight="0" 
                        marginWidth="0">
                    </iframe>
                </div>
            </div>
        )
    }
}
