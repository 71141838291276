import React, { useEffect, useState } from "react";
import localStorageHelper from "../../helpers/localStorage.js";

import { useNavigate, useLocation } from "react-router-dom";

const AutologinFourCo = () => {

	const [isLoginInProgress, setIsLoginInProgress] = useState('');
	const [isLoginFailed, setIsLoginFailed] = useState('');

	const allowableParams = ['access', 'refresh', 'username'];

	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		setIsLoginInProgress(true);
		setIsLoginFailed(false);
		
		let ok = true;
		const search = location.search;
		const searchParams = new URLSearchParams(search);

		if(searchParams.size > 0){
			// Checking available parameters
			if (searchParams.size === allowableParams.length) {
				allowableParams.forEach((value) => {
					if (searchParams.get(value) === undefined) {
						ok = false;
					}
				});

				if(ok){
					localStorageHelper.setAccessToken(searchParams.get('access'));
					localStorageHelper.setRefreshToken(searchParams.get('refresh'));
					localStorageHelper.setIsUserLoggedIn(true);
					localStorageHelper.setUsername(searchParams.get('username'));
					navigate("/home", { replace: true });
				}else{
					navigate("/login", { replace: true });
				}
			}
		}

		console.log("localStorage", localStorage);


	},[]);

	return (
		<></>
	);
	
}


export default AutologinFourCo;