import React from 'react';

import { Modal, ModalBody, ModalHeader, Row, Col, Label, Input, Button } from 'reactstrap';


export default class AtecoCodesModal extends React.Component {

    constructor(props) {
        super(props);

    }

    handleAtecoCodeChange = ( index, value ) => {

        if(!isNaN( value )) {

            this.props.onAtecoCodeChange( index, value );

        }

    }

    renderAtecoCode = ( atecoCode ) => 
        <React.Fragment key={atecoCode.id}>

            <Row>

                <Col md={1}>
                    <Label>{atecoCode.id}</Label>
                </Col>
                <Col>
                    <Input 
                        value={atecoCode.value}
                        maxLength={6}
                        onChange={ e => this.handleAtecoCodeChange( atecoCode.id, e.target.value)} />
                </Col>

            </Row>

        </React.Fragment>

    render() {

        return (

            <Modal id="edit-devices-modal" isOpen={this.props.isOpen}>

                    <ModalHeader toggle={this.props.toggle}>
                        Codici Ateco
                    </ModalHeader>

                    <ModalBody>
                        {this.props.atecoCodes.map( this.renderAtecoCode )}

                        <hr/>

                        <Button 
                            color="success"
                            onClick={this.props.toggle} > Chiudi </Button>

                    </ModalBody>

            </Modal>

        )

    }

}