import request from '../helpers/request.js';
import localStorage from '../helpers/localStorage.js';

const host = process.env.REACT_APP_API_RT_SERVICE_HOST;

export const get = () => {

    let url = host + process.env.REACT_APP_RT_RESELLERS_ENDPOINT;

    return new Promise((resolve, reject) => {
        request(
            host, { 
                method: 'GET', 
                url : url, 
                config : {
                    headers: {'Authorization': `Bearer ${localStorage.getAccessToken()}`},
                    withCredentials: true
                }}
        ).then( 
            response => {
                resolve(response)
            }
        ).catch( err => {
            reject(err);
        })
    });

}

export const getSettings = ( idReseller ) => {

    let url = host + process.env.REACT_APP_RT_RESELLERS_ENDPOINT + `/${idReseller}` + process.env.REACT_APP_RT_SETTINGS_ENDPOINT;

    return new Promise((resolve, reject) => {
        request(
            host, { 
                method: 'GET', 
                url : url, 
                config : {
                    headers: {'Authorization': `Bearer ${localStorage.getAccessToken()}`},
                    withCredentials: true
                }}
        ).then( 
            response => {
                resolve(response)
            }
        ).catch( err => {
            reject(err);
        })
    });

}

export const updateSettings = ( idReseller, settings ) => {

    const url = host + process.env.REACT_APP_RT_RESELLERS_ENDPOINT + `/${idReseller}` + process.env.REACT_APP_RT_SETTINGS_ENDPOINT;

    let bodySettings = [];
    for (const setting of settings ) {

        bodySettings.push({
            id:     setting['id'],
            value: setting['value']
        });
        
    }

    const body = {
        settings : bodySettings
    };

    return new Promise((resolve, reject) => {
        request(
            host, { 
                method: 'PUT', 
                url : url,
                body: body, 
                config : {
                    headers: {'Authorization': `Bearer ${localStorage.getAccessToken()}`},
                    withCredentials: true
                }}
        ).then( 
            response => {
                resolve(response)
            }
        ).catch( err => {
            reject(err);
        })
    });

}