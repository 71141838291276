const localStorageHelper = {

    /**
     * 
     */
    getUsername: function() {
        return localStorage.getItem("username") || "";
    },

    /**
     * 
     * @param {*} username 
     */
    setUsername: function(username) {
        localStorage.setItem("username", username);
    },

    /**
     * 
     * @param {*} isUserLoggedIn 
     */
    setIsUserLoggedIn: function(isUserLoggedIn){
        localStorage.setItem("isUserLoggedIn", isUserLoggedIn);
    },

    /**
     * Checks wether user is logged in or not
     */
    getIsUserLoggedIn: function(){
        let isUserLoggedIn = localStorage.getItem('isUserLoggedIn') == 'true';
        return isUserLoggedIn;
    },

    /**
     * get access token obtained after login request
     */
    getAccessToken: function(){
        return localStorage.getItem('access_token') || false;
    },

    /**
     * 
     * @param {*} accessToken 
     */
    setAccessToken: function(accessToken){
        localStorage.setItem("access_token", accessToken);
    },

    /**
     * 
     */
    getRefreshToken: function(){
        return localStorage.getItem("refresh_token") || false;
    },

    /**
     * 
     * @param {*} refreshToken 
     */
    setRefreshToken: function(refreshToken){
        localStorage.setItem("refresh_token", refreshToken);
    },

    /**
     * 
     */
    removeRefreshToken: function(){
        localStorage.removeItem('refresh_token');
    },
};

export default localStorageHelper;