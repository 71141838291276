import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from 'react-router-dom';

const Logout  = (props) => {

    const navigate = useNavigate()
    
    const logout = () => {
        props.changeUserLoggedState();
        localStorage.clear();
        navigate('/login');
    }


    return (
        <a id="sidebarLogout" onClick={logout}>
            <FontAwesomeIcon icon={faSignOutAlt} size="2x" />
        </a>
    )
    
}

export default Logout;
