import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Input, Label, Row } from 'reactstrap';
import "./imageInput.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faUpload } from '@fortawesome/free-solid-svg-icons';
import DefaultLoader from '../../../layout/Loader/DefaultLoader';
import Divider from '../Divider/Divider';

/**
 * Beware of image sizes because if too big they are going to exceedes POST limit on save (undefined index customer_module error in BE)
 */

const fromRawToArrayBuffer = (raw) => {

	if(raw === "") return;

	if(typeof raw === "string") {
			// given base 64 encoded unsigned short value 16 bit from PHP, create array buffer in javascript
			let rawLength = 0;
			let decodedData = atob(raw);
			rawLength = decodedData.length;
			let byteArray = new Uint8Array(rawLength);
		
			for(let i = 0; i < rawLength; i++){
				byteArray[i] = decodedData.charCodeAt(i);
			}
		
			return byteArray.buffer;
	}
	else {
		return raw;
	}

}

const calculateAspectRatioFit = (srcWidth, srcHeight, maxWidth, maxHeight) => {
	let ratio = [maxWidth / srcWidth, maxHeight / srcHeight ];
	ratio = Math.min(ratio[0], ratio[1]);
	return { width:srcWidth*ratio, height:srcHeight*ratio };
}

const ImageInput = (props) => {
	const DEFAULT_IMAGE_OBJ = {isLoaded: false, width: "", height: "", content: null, extension: "", rgb: [], rgbBytes: new Uint16Array()};
	const loadedImage = props.loadedImage ?? null;

	const loadImgValues = loadedImage.rawData !== "" && loadedImage.content !== "" ? {
		isLoaded: true,
		width: loadedImage.width,
		height: loadedImage.height,
		content: loadedImage.content,
		extension: loadedImage.ext, 
		rgb: Array.from(new Uint16Array(fromRawToArrayBuffer(loadedImage.rawData))), 
		rgbBytes: new Uint16Array(fromRawToArrayBuffer(loadedImage.rawData))
	} : DEFAULT_IMAGE_OBJ;


	const [file, setFile] = useState(null); // type File
	const [isError, setIsError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [imageObj, setImageObj] = useState(loadImgValues);
	// const [fileContent, setFileContent] = useState(null);

	const id = props.id ?? "0";
	const title = props.title ?? null;
	const containerName = props.containerName ?? "container-file-uploader-" + id;
	const inputName = props.inputName ?? "file-uploader-" + id;
	const canvasName = props.canvasName ?? "canvas-file-uploader-" + id;
	const resize = props.resize ?? {width: null, height: null};
	const functionManipulateImageForParent = props.setImageForParent ?? null;
	const allowedMimes = props.mimes ?? ["image/png", "image/jpg", "image/jpeg", "image/webp", "image/svg+xml", "image/bmp"];

	let fileContent = null;

	useEffect(() => {
		if(imageObj && imageObj.isLoaded) {
			loadOnCanvas();
		}
		if (functionManipulateImageForParent) {
			setImageObjectForParent(imageObj);
		}
	}, [imageObj]);

	const loadOnCanvas = () => {
		// Setting up variables to send to drawimage function 
		const canvas = document.getElementById(canvasName);
		canvas.getContext("2d");
		canvas.width = imageObj.width;
		canvas.height = imageObj.height;
		drawRGB565ImageDataToCanvas(canvas, imageObj.rgbBytes, imageObj.width, imageObj.height);
	}


	const setImageObjectForParent = (obj) => {

		const parentObject = {
			id: id,
			height: obj?.height ?? "",
			width: obj?.width ?? "",
			content: obj?.content ?? "",
			ext: obj?.extension ?? "",
			rawData: obj?.rgbBytes ?? ""
		}
		functionManipulateImageForParent(parentObject);
	}

	const imageValidationError = (fileObj) => {
		let error = false;
		setErrorMessage("");
		if(fileObj) {
			// File validation
			if(!allowedMimes.includes(fileObj.type)) {
				error = true;
				setErrorMessage("File non supportato, sono accettate immagini nel formato: " + allowedMimes.join(", "));
			}
	
			if(fileObj.size <= 0) {
				error = true;
				setErrorMessage("Il file è vuoto");
			}
		}
		setIsError(error);
		return error;
	}


	const convertImageDataToRGB565 = (imageData) => {
		const data = imageData.data;
		const rgb565Data = [];
	
		for (let i = 0; i < data.length; i += 4) {
			// Convert 8-bit red to 5-bit red. and cap it to max integer value for 5 bit
			const red5 = Math.round(data[i] / 255 * 31)
			// Convert 8-bit green to 6-bit green. and cap it to max integer value for 6 bit
			const green6 = Math.round(data[i + 1] / 255 * 63)
			// Convert 8-bit blue to 5-bit blue. and cap it to max integer value for 5 bit
			const blue5 = Math.round(data[i + 2] / 255 * 31)
			
			// Shift the red value to the left by 11 bits.
			const red5_shifted = red5 << 11
			// Shift the green value to the left by 5 bits.
			const green6_shifted = green6 << 5
			// Combine the red, green, and blue values
			const rgb565 = red5_shifted | green6_shifted | blue5
			rgb565Data.push(rgb565);
		}
		// setImageObj({...imageObj, rgb: rgb565Data, rgbBytes: new Uint8Array(rgb565Data)})
		return rgb565Data;
	}

	const resizeAndCenterImagePixelArray = (imageData, newWidth, newHeight) => {
		var srcData = imageData.data;
		var srcWidth = imageData.width;
		var srcHeight = imageData.height;

		const ratioSize = calculateAspectRatioFit(srcWidth, srcHeight, newWidth, newHeight);
		const keepRatioW = ratioSize.width;
		const keepRatioH = ratioSize.height;

		// Calculate the offset to center the image
		let offsetX = Math.max(0, Math.floor((newWidth - keepRatioW) / 2));
		let offsetY = Math.max(0, Math.floor((newHeight - keepRatioH) / 2));

		// Create new ImageData object with black pixels
		const resizedImageData = new Uint8ClampedArray(newWidth * newHeight * 4).fill(0); // Fill with black pixels

		var xRatio = srcWidth / keepRatioW;
		var yRatio = srcHeight / keepRatioH;

		// Copy original image data to center of new ImageData object
		for (let y = 0; y < keepRatioH; y++) {
			for (let x = 0; x < keepRatioW; x++) {
				var srcX = Math.floor(x * xRatio);
				var srcY = Math.floor(y * yRatio);
				var srcIndex = (srcY * srcWidth + srcX) * 4 || 0;
				let dstIndex = (((offsetY + y) * newWidth) + (offsetX + x)) * 4;

				resizedImageData[dstIndex] = srcData[srcIndex]; // red
				resizedImageData[dstIndex + 1] = srcData[srcIndex + 1]; // green
				resizedImageData[dstIndex + 2] = srcData[srcIndex + 2]; // blue
				resizedImageData[dstIndex + 3] = srcData[srcIndex + 3]; // alpha
			}
		}

		return new ImageData(resizedImageData, newWidth, newHeight); // Create new ImageData
	}

	// Function to draw RGB565 image data on a canvas
	const drawRGB565ImageDataToCanvas = (canvas, rgb565Data, width, height) => {
		const _canvas = canvas;
		_canvas.width = width;
		_canvas.height = height;
		const ctx = _canvas.getContext('2d');
		const imageData = ctx.createImageData(width, height);
		const data = imageData.data;

		for (let i = 0; i < rgb565Data.length; i++) {
			const rgb565Value = rgb565Data[i];
			const index = i * 4;

			// Extract RGB components from RGB565 value
			const red = ((rgb565Value >> 11) & 0x1F) << 3;
			const green = ((rgb565Value >> 5) & 0x3F) << 2;
			const blue = (rgb565Value & 0x1F) << 3;

			// Set pixel values in the ImageData object
			data[index] = red;
			data[index + 1] = green;
			data[index + 2] = blue;
			data[index + 3] = 255; // Alpha channel (fully opaque)
		}
		ctx.putImageData(imageData, 0, 0);
	}

	const getFileContentFromCanvas = (canvas) => {
		// Get file content base64 encoded from canvas
		return canvas.toDataURL().split(",")[1];
	}

	/**
	 * Input0: new Image object loaded from upload
	 * Input1: new File object from the upload process, just to take the name and extract the extension
	 * The function `writeOnCanvas` takes an image, resizes it if necessary, converts its pixel data to
	 * RGB565 format, and draws it on a canvas element.
	 */
	const writeOnCanvas = (image, file) => {
		
		if(image) {
			const canvas = document.getElementById(canvasName);
			const ctx = canvas.getContext("2d");
			canvas.width = image.width;
			canvas.height = image.height;
			// 
			let destCanvasW = image.width;
			let destCanvasH = image.height;
			//
			ctx.drawImage(image, 0, 0);
			// Assume imageData is obtained using getImageData
			let imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
			if(resize.width && resize.height) {
				// Resizing image to 320x240
				destCanvasW = resize.width;
				destCanvasH = resize.height;
				imageData = resizeAndCenterImagePixelArray(imageData, destCanvasW, destCanvasH);
			}
			// Convert imageData object data from 8888 (32bit)=> 565 rgb (16bit)
			const rgb565Data = convertImageDataToRGB565(imageData);
			// 
			drawRGB565ImageDataToCanvas(canvas, rgb565Data, destCanvasW, destCanvasH);
			// Get file content (resized too?) from canvas
			fileContent = getFileContentFromCanvas(canvas);

			setImageObj({...imageObj, isLoaded: true, width: destCanvasW, height: destCanvasH, content: fileContent, extension: file.name.split(".")[1], rgb: rgb565Data, rgbBytes: new Uint16Array(rgb565Data)})

		}
	};

	const onChangeHandler = (event) => {
		setIsLoading(true);
		const file = event.target.files[0];
		if(file) {
			// setFile(file);
			const reader = new FileReader()
			reader.onload = (event) => {
				if(!imageValidationError(file)) {
					const img = new Image();
					img.onload = (event) => {
						writeOnCanvas(img, file);
					};
					img.src = event.target.result;
				}
				else {
					setImageObj(null);
					setFile(null);
					resetCanvas();
				}
			}
			reader.readAsDataURL(file);
		}
		setIsLoading(false);
	}

	let inputFileTypeComponent = (
		<>
			<Input className="inputfile onlyButton" type="file" name={inputName} id={inputName} onChange={onChangeHandler} />
			<Label for={inputName}>
				<FontAwesomeIcon icon={faUpload} /> {" "}
				{ file ? file.name : "Seleziona file" }
			</Label>
		</>
	);
	if(props.full) {
		inputFileTypeComponent = (
			<>
				<Input className="inputfile full" type="file" name={inputName} id={inputName} onChange={onChangeHandler} accept="image/*" />
				<Label for={inputName}>
					<span>{file && file.name}</span>
					<strong>
						<FontAwesomeIcon icon={faUpload} /> {" "}
						Seleziona file
					</strong>
				</Label>
			</>	
		);
	}

	const removeImageHandler = () => {
		setImageObj(null);
		setFile(null);
		setIsError(false);
		resetCanvas();
	}

	const resetCanvas = () => {
		const canvas = document.getElementById(canvasName);
		const context = canvas.getContext('2d');
		context.clearRect(0, 0, canvas.width, canvas.height);
	}

	return (
		<>
			{title && 
				<Divider>{title}</Divider>
			}
			{isError && (
				<Row form>
					<Col xs={12}>
						<Alert color="danger">{errorMessage}</Alert>
					</Col>
				</Row>
			)}
			<Row>
				<Col md={10} sm={10}>
					{inputFileTypeComponent}
				</Col>
				<Col md={2} sm={2}>
					{((imageObj && imageObj.isLoaded) || isError) &&
						<Button color="danger" onClick={removeImageHandler}><FontAwesomeIcon icon={faTrash} /></Button>
					}
				</Col>
			</Row>
			<Row>
				<Col sm={12}>
					{
					isLoading && 
						<div className='preloader'>
							<DefaultLoader />
						</div>
					}
					<div className={containerName}>
						<canvas id={canvasName} style={{ maxWidth: "100%", marginLeft: "auto", marginRight: "auto", display: "block" }}></canvas>
					</div>
				</Col>
			</Row>
		</>
	);
}

export default ImageInput;