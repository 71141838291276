import React, { useEffect, useState } from 'react'
import {Modal, ModalHeader, ModalBody } from 'reactstrap';
import Edit from '../Edit';
import * as ConfigurationAPI from '../../../services/configuration.js';
import httpStatus from 'http-status-codes';
import AlertDanger from '../../Common/Alert/AlertDanger';
import DefaultLoader from '../../../layout/Loader/DefaultLoader';

const  EditConfigurationModal = (props) => {

    const [editConfiguration, setEditConfiguration] = useState({
        rtConfig: null,
        isConfigurationLoading: true,
        isEditModalOpen: true,
        hasRaisedAnError: false,
        errorCode: null
    });

    useEffect(() => {
        if(props.currentConfigurationId){
            getConfiguration(props.currentConfigurationId);
        }
    },[props.currentConfigurationId])


    const closeEditModalInInsertOperation = (isSuccessfulOperation, configurationId, customerModuleWithoutReceiptData) => {
        props.closeEditModalInInsertOperation(isSuccessfulOperation, configurationId, customerModuleWithoutReceiptData);
    }

    const getConfiguration = (configId) => {

        ConfigurationAPI
            .getById(configId)
            .then(response => { 
                setEditConfiguration((prevState) => ({
                    ...prevState,
                    rtConfig : response.status === httpStatus.OK ? response.data : [],
                    errorCode: response.status === httpStatus.OK ? "" : response.status,
                    isConfigurationLoading: false
                }))
            })
            .catch(err => {
                setEditConfiguration((prevState) => ({
                    ...prevState,
                    isConfigurationLoading: false,
                    hasRaisedAnError: true,
                    errorCode: httpStatus.INTERNAL_SERVER_ERROR
                }))
            });
    }

    return (
        <React.Fragment>
            {/* toggle={this.toggle} */}
            <Modal id="edit-configuration-modal" isOpen={editConfiguration.isEditModalOpen} size="xl" fade={false} >
                <ModalHeader toggle={props.toggleEditModal}>
                    { props.isDuplicationAction ? 
                        `Crea nuovo modello` : 
                        `Modello #${props.currentConfigurationCode}`
                    }
                </ModalHeader>
                <ModalBody>
                    {
                        editConfiguration.isConfigurationLoading ? 
                        <DefaultLoader />
                    : 
                        editConfiguration.hasRaisedAnError ? 
                            <AlertDanger errorCode={editConfiguration.errorCode} />
                            :
                            <Edit 
                                isDuplicationAction={props.isDuplicationAction}
                                idDevice = {props.idDevice} 
                                configurationId={props.currentConfigurationId} 
                                data={editConfiguration.rtConfig} 
                                closeEditModalInInsertOperation={closeEditModalInInsertOperation} 
                                reloadConfigurationList={props.reloadConfigurationList}
                            /> 
                    }
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
    
}

export default EditConfigurationModal;
