import React, { Component } from 'react'
import {Modal, ModalHeader, ModalBody, ModalFooter, Button} from 'reactstrap';
import { faTimes, faCircleNotch, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class DeleteConfigurationModal extends Component {

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.isDeleteModalOpen} fade={false} toggle={this.toggleDeleteModal}>
                    <ModalHeader toggle={this.props.toggleDeleteModal}>Attenzione</ModalHeader>
                    <ModalBody>
                        <p>Confermi l'eliminazione del modello?</p>
                    </ModalBody>
                    <ModalFooter>
                        { this.props.isConfigDeleteInProgress ? <FontAwesomeIcon icon={faCircleNotch} spin size="2x" className="float-left"/> : "" }
                        <Button color="danger" 
                            disabled={this.props.isConfigDeleteInProgress || this.props.isDeleteSuccessful !== null ? true : false}
                            onClick={this.props.deleteConfiguration}><FontAwesomeIcon icon={faTrash} />
                            {' '}Elimina
                        </Button>
                        {' '}
                        <Button
                            disabled={this.props.isConfigDeleteInProgress ? true : false}
                            onClick={this.props.toggleDeleteModal}><FontAwesomeIcon icon={faTimes} />
                            {' '}Chiudi
                        </Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        )
    }
}
