import request from '../helpers/request.js';
import localStorage from '../helpers/localStorage.js';

import qs from 'querystring';

const host = process.env.REACT_APP_API_RT_SERVICE_HOST;

const POST_BODY_USERNAME_KEY    = 'username';
const POST_BODY_PASSWORD_KEY    = 'password';
const POST_BODY_EMAIL_KEY       = 'email';
const POST_BODY_FIRSTNAME_KEY   = 'first_name';
const POST_BODY_LASTNAME_KEY    = 'last_name';
const POST_BODY_FISCALCODE_KEY = 'fiscal_code';


export const getUserById = (idUser, token = localStorage.getAccessToken()) => {
	let url = host + process.env.REACT_APP_RT_RESELLERS_ENDPOINT + "/get-user" + `/${idUser}`;
	return new Promise((resolve, reject) => {
		request(
			host, { 
				method: 'GET', 
				url : url, 
				config : {
					headers: {'Authorization': `Bearer ${token}`},
					withCredentials: true
				}}
		).then( 
			response => {
				resolve(response)
			}
		).catch(err => {
			reject(err);
		})
	});
}

export const getAllUsers = ( idReseller ) => {

    let url = host + process.env.REACT_APP_RT_RESELLERS_ENDPOINT + `/${idReseller}` + process.env.REACT_APP_RT_USERS_ENDPOINT;

    return new Promise((resolve, reject) => {

        if(!idReseller) return resolve({
            data: []
        });

        request(
            host, { 
                method: 'GET', 
                url : url, 
                config : {
                    headers: {'Authorization': `Bearer ${localStorage.getAccessToken()}`},
                    withCredentials: true
                }}
        ).then( 
            response => {
                resolve(response)
            }
        ).catch( err => {
            reject(err);
        })
    });

}

export const unlinkUser = ( idUser, idReseller ) => {

    let url = host + process.env.REACT_APP_RT_RESELLERS_ENDPOINT + `/${idReseller}` + process.env.REACT_APP_RT_USERS_ENDPOINT + `/${idUser}`;

    return new Promise((resolve, reject) => {

        if(!idReseller || !idUser) reject();

        request(
            host, { 
                method: 'DELETE', 
                url : url, 
                config : {
                    headers: {'Authorization': `Bearer ${localStorage.getAccessToken()}`},
                    withCredentials: true
                }}
        ).then( 
            response => {
                resolve(response)
            }
        ).catch( err => {
            reject(err);
        })

    })

}

export const updateUser = ( idUser, idReseller, fiscalCode, isDefault ) => {

    let url = host + process.env.REACT_APP_RT_RESELLERS_ENDPOINT + `/${idReseller}` + process.env.REACT_APP_RT_USERS_ENDPOINT + `/${idUser}`;

    return new Promise((resolve, reject) => {

        if(!idReseller || !idUser) reject();

        let body = {};

        if(fiscalCode)
            body['fiscal_code'] = fiscalCode;

        if(isDefault !== null)
             body['is_default'] = isDefault ? 1 : 0;

        request(
            host, { 
                method: 'PUT', 
                url : url, 
                body: qs.stringify(body),
                config : {
                    headers: {'Authorization': `Bearer ${localStorage.getAccessToken()}`},
                    withCredentials: true
                }}
        ).then( 
            response => {
                resolve(response)
            }
        ).catch( err => {
            reject(err);
        })

    })

}

export const addUser = ( idReseller, username, password, email, firstName, lastName, fiscalCode ) => {

    let url = host + process.env.REACT_APP_RT_RESELLERS_ENDPOINT + `/${idReseller}` + process.env.REACT_APP_RT_USERS_ENDPOINT;

    return new Promise((resolve,reject) => {
    
        let body = {};

        body[POST_BODY_USERNAME_KEY]    = username;
        body[POST_BODY_PASSWORD_KEY]    = password;
        body[POST_BODY_EMAIL_KEY]       = email;
        body[POST_BODY_FIRSTNAME_KEY]   = firstName;
        body[POST_BODY_LASTNAME_KEY]    = lastName;
        body[POST_BODY_FISCALCODE_KEY]  = fiscalCode;

        request(
            host,
            {
                method: 'POST',
                url: url,
                body : body,
                config : {
                    headers: {'Authorization': `Bearer ${localStorage.getAccessToken()}`},
                    withCredentials: true
                }
            }
            
        ).then( 
            response => {
                resolve(response)
            }
        ).catch( err => {
            reject(err);
        })

    })

}