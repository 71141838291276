import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
// import customHistory from "./history.js";
import Login from "./view/Login/Login.jsx";
import Home from "./view/Home/Home.jsx";
import RtList from "./view/RtList/RtList.jsx";
import Configuration from "./view/Configuration/Configuration.jsx";
import AutologinFourCo from "./view/Autologin/AutologinFourCo.jsx";
import Root from "./view/Autologin/Autologin.jsx";

const App = () => {
	return (
		<Routes>
			<Route exact path="/" element={<Root />} />
			<Route exact path="/autologin" element={<AutologinFourCo />} />
			<Route exact path="/login" element={<Login />} />
			<Route exact path="/home" element={<Home />}>
				<Route index element={<Navigate to="rt-list" />} />
				<Route exact path="/home/rt-list" element={<RtList />} />
				<Route exact path="/home/configuration" element={<Configuration />} />
			</Route>
		</Routes>
	);
};

export default App;
