import React, { Component } from 'react'
import {Alert, Modal, ModalHeader, ModalBody, ModalFooter, Button} from 'reactstrap';
import { faPlus, faTable } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class ResponseModal extends Component {


    render() {
        return (
            <div>
                <Modal backdrop={true} isOpen={this.props.isModalOpen} fade={false} toggle={this.toggle}>
                    <ModalHeader toggle={this.props.toggleConfirmationModal}>Salvataggio Modello</ModalHeader>
                    <ModalBody>
                        <Alert color="success">
                            Modello Salvato con successo!
                        </Alert>
                        <h6>Dettagli Modello</h6>
                        <table>
                            <tbody>
                                <tr><td>Id:</td><td>{ this.props.idConfiguration }</td></tr>
                                <tr><td>Nome Attività:</td><td>{ this.props.activityName }</td></tr>
                                <tr><td>Ragione Sociale:</td><td>{ this.props.businessName }</td></tr>
                                <tr><td>Partita IVA:</td><td>{ this.props.vatCode }</td></tr>
                                <tr><td>Codice Fiscale:</td><td>{ this.props.fiscalCode }</td></tr>
                            </tbody>
                        </table>
                    </ModalBody>
                    <ModalFooter>
                        { typeof this.props.createNewConfiguration == "undefined" ? "" : 
                        <Button outline  color="primary" onClick={this.props.createNewConfiguration}><FontAwesomeIcon icon={faPlus} />{' '}Nuovo modello</Button> }
                        {' '}
                        { typeof this.props.goBackToConfigurationList == "undefined" ? "" : 
                        <Button outline  color="danger" onClick={this.props.goBackToConfigurationList}><FontAwesomeIcon icon={faTable} />{' '}Torna alla lista</Button> }
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}
