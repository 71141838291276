import React, { Component } from 'react'

export class FlagHint extends Component {
    constructor( props ) {
        super(props);

    }

    render() {
        return (
            <React.Fragment>
                <div style={{fontSize:'10px'}} id="hint" dangerouslySetInnerHTML={{ __html: this.props.hint}} />
            </React.Fragment>
        )
    }
}

export default FlagHint
