import { faCaretSquareDown, faCaretSquareUp, faCircleNotch, faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { memo, useEffect, useState } from 'react';
import { isMobile } from "react-device-detect";
import { useOutletContext } from 'react-router-dom';
import { Alert, Col, Input, Row, Table } from 'reactstrap';
import { getAll, getByFiscalNumber } from '../../services/devices.js';
import useScreenOrientation from '../Common/Hook/ScreenOrientation.jsx';
import TablePagination from '../Common/Pagination/TablePagination';
import RtListRow from './RtListRow.jsx';
import AutoConfigRtModal from './RtModal/AutoConfigRtModal.jsx';
import RtModal from './RtModal/RtModal';

const RtList = () =>{

    const [rtList, setRtList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [filters, setFilters] = useState(
        {
            order_params:{
                name: "fiscal_number",
                sort: "asc",
            },
            search_params: {
                fiscal_number: "",
                activity_name: "",
                vat_code: "",
                created_at: "",
				mobile_search: ""
            },
            pageSize: 10,
            currentPage: 1
        }
    )

    const [selectedRt, setSelectedRt] = useState({
		id: null,
		fiscal_number: null,
		serial_number: null,
		idConfiguration: null,
		idReseller: null,
		created_at: null,
		updated_at: null,
		vfp_expiration_date: null,
		isEnabledRemoteUpgrade: null,
		remoteUpgradeEnabledBy: null,
		configuration: {
			id: null,
			activity_name: null,
			fiscal_code: null,
			address: null,
			zip_code: null,
		},
		dgfe: [],
		status: {
			id: null,
			idDevice: null,
			dailyClosureCount: null,
			certificateId: null,
			certificateExpiresAt: null,
			cutCount: null,
			printedPaperLenMeters: null,
			lastVFPDate: null,
			archivedDgfeCount: null,
			fiscalResetCount: null,
			resellerBlockId: null,
			censitoSperimentazione: null,
			fiscalActivationInfo_Date: null,
			fiscalActivationInfo_VATCode: null,
			lastVFP_CF: null,
			lastVFP_PIVA: null,
			lastVFPResult: null,
			currentRTStatus: null,
			certificateValidFrom: null,
			macAddress: null,
			dailyClosureDate: null,
			dailyClosureResiduals: null,
			firmwareVersion: null,
			created_at: null,
			updated_at: null,
		}
    });
    const [totalPages, setTotalPages] = useState(0);
    const [totalRecordCount, setTotalRecordCount] = useState(0);
    const [matricolaParam, setMatricolaParam] = useState(null);
    const [isOpenRtModal, setIsOpenRtModal] = useState(false);
    const [autoConfigurationModal, setAutoConfigurationModal] = useState(false);
	const [errorMatricola, setErrorMatricola] = useState(false);

    const {currentMatricola, setCurrentMatricola} = useOutletContext();

    const { orientation } = useScreenOrientation();
	
	// const mobileHiddenClass = "d-none d-md-table-cell"
	const mobileHiddenClass = ""

	useEffect(() => {
		if(currentMatricola) {
			getDeviceByUrl(currentMatricola)
		}
	}, []);

    useEffect(() => {
        // const controller = new AbortController();
        // const signal = controller.signal;
        getRtList();

        // return () => {controller.abort()}
    },[filters]);

    useEffect(() => {
        if(currentMatricola){
            // setMatricolaParam(currentMatricola);
            // setAutoConfigurationModal(true);
        }
    },[currentMatricola]);


	const getDeviceByUrl = async(matricola) => {
		setIsLoading(true);
		// retrieve device info from given matricola (passed by get param in url m=)
		try {
			const device = await getByFiscalNumber(matricola);
			if(device.status === 200) {
				if(Object.keys(device.data).length > 0 && device.data.configuration) {
					// Device found
					openRtModal(device.data.id, device.data.configuration.address, device.data.configuration.zip_code);
				}
				else {
					// Device not found
					setMatricolaParam(matricola);
					setAutoConfigurationModal(true);
				}
			}
			else {
				setErrorMatricola(true);
			}
		}
		catch (error) {
			setIsLoading(false);
			setErrorMatricola(true);
		}
		setIsLoading(false);
	}
    
    const getRtList = async() => {

        setIsLoading(true);
        
        const devices = await getAll(filters.currentPage, filters.pageSize, filters.search_params, filters.order_params);
        
        if(devices.status === 200){
            setRtList(devices.data.data);
            setTotalPages(devices.data.total_pages);
            setTotalRecordCount(devices.data.total_record_count);
        }else {
            setRtList([]);
            setTotalRecordCount(0);
        }
        setIsLoading(false);
    }

    const handleChangeViewedItems = (event) => {
        setFilters((prevFilters) => (
            {...prevFilters,
            pageSize: event.target.value}
        ))
    }

    const changePage = (value) => {
        setFilters((prevFilters) => (
            {...prevFilters,
            currentPage: value}
        ))
    }
    
    const openRtModal = (id, address, zip_code) => {
        setSelectedRt({...selectedRt, id: id, address: address, zip_code: zip_code })
        setIsOpenRtModal(true);
    }

    const toggleRtModal = () => {
        setSelectedRt({...selectedRt, id: null, address: null, zip_code: null });
        setIsOpenRtModal(false);
        getRtList();
    }

    /**
     * changes column and/or sorting
     */
    const orderByColumn = (columnName) => {

        let sortedResult= '';

        if(columnName === filters.order_params.name){
            sortedResult = filters.order_params.sort === "desc" ? "asc" : "desc";
        }
        else {
            if(columnName === "fiscal_number") sortedResult= "desc";
            else sortedResult = "asc";
        }

        setFilters((prevFilters) => (
            {
                ...prevFilters,
                order_params: {
                    ...prevFilters.order_params,
                    name: columnName,
                    sort: sortedResult
                }
            }
        ))

    }

    /**
     * listens for input text changes over configurations table search bar
     */
    const onChangeSearchInput = (event, inputName) => {
        setFilters((prevFilters) => (
            {
                ...prevFilters,
                search_params:{
                    ...prevFilters.search_params,
                    [inputName]: event.target.value.trim()
                }
                
            }
        ))
    }

    const renderOrderIcon = (column_name) => {
        if(filters.order_params.name === column_name){
            return (
                <span className="float-right">
                        <FontAwesomeIcon icon={filters.order_params.sort === "desc" ? faCaretSquareDown : faCaretSquareUp} /> 
                </span>
            )
        }else{
            return (
                <span className="float-right"></span>
            )
        }
    }


    const handleAutoConfigCloseModal = () => {
        setAutoConfigurationModal(false);
        setMatricolaParam(null);
        setCurrentMatricola(null);
    }

    return (
        <div className="col-md-12">
			<Alert isOpen={errorMatricola} color="danger">La matricola inserita non è corretta</Alert>
            <div className="card text-left">
                <div className="card-header">
                    <FontAwesomeIcon icon={faPrint} /> Lista dispositivi RT
                </div>

                {selectedRt.id === null ? null : 
                    <RtModal 
                        id={selectedRt.id}
                        address={selectedRt.address}
                        zip_code={selectedRt.zip_code}
                        isOpenRtModal={isOpenRtModal} 
                        toggleRtModal={toggleRtModal} 
						device={selectedRt}
                    /> 
                }

                <AutoConfigRtModal
                    isOpen={autoConfigurationModal}
                    matricola={matricolaParam}
                    toggleAutoConfigRtModal={handleAutoConfigCloseModal} 
                />

                {isMobile && orientation.includes('portrait') ? <div style={{paddingTop: 10, paddingLeft: 10, color: 'red'}}> Per una corretta visualizzazione, ruotare il dispositivo  </div> : null}

                <div className="card-body">  
                    <Row>
                        <Col>
                            Visualizza <select className="select" onChange={handleChangeViewedItems}>
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select> Elementi
                            <div className="clearfix">&nbsp;</div>
                        </Col>
                    </Row>
					{isMobile &&
						<Row>
							<Col>
								<Input type="text" placeholder="Ricerca..." value={filters.search_params.mobile_search} onChange={e => onChangeSearchInput(e, "mobile_search")} />
								<div className="clearfix">&nbsp;</div>
							</Col>
						</Row>
					}
                    <Row>
                        <Col>
                            <Table striped responsive hover size="sm">
                                <thead>
                                    <tr>
                                        { !isMobile && <th className={mobileHiddenClass} width='1%'>Stato</th> }
                                        <th width='1%' style={{cursor: 'pointer'}} onClick={e => orderByColumn("fiscal_number")}>
                                            Matricola Fiscale {renderOrderIcon("fiscal_number")}
                                        </th>
                                        <th width='1%' style={{cursor: 'pointer'}} onClick={e => orderByColumn("activity_name")}>
                                            Esercizio {renderOrderIcon("activity_name")}
                                        </th>
                                        <th width='1%' style={{cursor: 'pointer'}} onClick={e => orderByColumn("vat_code")}>
                                            P. IVA {renderOrderIcon("vat_code")}
                                        </th>
                                        { !isMobile && <th className={mobileHiddenClass} width='1%' /*style={{cursor: 'pointer'}} onClick={e => this.orderByColumn("created_at")} */>
                                            Creazione {/*this.renderOrderIcon("created_at")*/}
                                        </th>
										}
                                        { !isMobile && <th className={mobileHiddenClass} width='1%'>
                                            Scadenza DGFE 
                                        </th> }
                                        { !isMobile && <th className={mobileHiddenClass} width='1%'>
                                            Scadenza VFP
                                        </th> }
                                        { !isMobile && <th className={mobileHiddenClass} width='1%' style={{textAlign:'center'}}>
                                            Upgrade Remoto
                                        </th> }
                                        { !isMobile && <th className={mobileHiddenClass} width='1%' colSpan="2">Azioni</th> }
                                    </tr>
									{!isMobile && 
										<tr>
											<th></th>
											{/*<th></th>*/}
											<th>
												<Input type="text" value={filters.search_params.fiscal_number} onChange={e => onChangeSearchInput(e, "fiscal_number")} />
											</th>
											<th>
												<Input type="text" value={filters.search_params.activity_name} onChange={e => onChangeSearchInput(e, "activity_name")} />
											</th>
											<th>
												<Input type="text" value={filters.search_params.vat_code} onChange={e => onChangeSearchInput(e, "vat_code")} />
											</th>
											<th>
												{ /*<Input type="text" value={this.state.search_params.created_at} onChange={e => this.onChangeSearchInput(e, "created_at")} /> */ }
											</th>
											<th></th>
											<th></th>
											<th></th>
											<th colSpan="2"></th>
										</tr>
									}
                                </thead>
                                <tbody>
                                    { !isLoading ? 
                                        rtList.map((device) => {
                                            return (
                                                <RtListRow key={device.id} device={device} openRtModal={openRtModal} />
                                            )
                                        }) : null
                                    }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    { isLoading ? 
                        <Row>
                            <Col>
                                <div style={{textAlign: "center"}}>
                                    <p>Caricamento in corso...</p>
                                    <p><FontAwesomeIcon icon={faCircleNotch} spin size="3x" /></p>
                                </div>
                            </Col>
                        </Row> : ""
                    } 
                    
                    <Row>
                        <Col>
                            <div className="mt-5">
                                <TablePagination disabled={isLoading || rtList.length === 0 ? true : false}
                                    currentPage={filters.currentPage} 
                                    totalPages={totalPages} 
                                    pageSize={filters.pageSize} 
                                    recordCount={totalRecordCount}
                                    changePage={changePage}
                                />
                            </div>
                        </Col>
                    </Row> 
                    
                </div>
            </div>
        </div>
    );
    
}

export default memo(RtList);
