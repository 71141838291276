import React, { Component } from 'react';
import "./Divider.css"
import { Row } from 'reactstrap';

export default class Divider extends Component
{
	constructor(props) {
		super(props);
		this.state = {}
	}
	
	render() {
		return (
			<Row className="divider">
				<div className="container">
					<div className="border" />
					<span className={this.props.children ? "content" : ""}>{this.props.children}</span>
					<div className="border" />
				</div>
			</Row>
		);
	}
}