export const getBase64 = function(file) {
    return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            resolve(reader.result);
        };
        reader.onerror = function (error) {
            reject(error);
        };
    });
}

 export const getImageTypeFromBase64 = function(base64Image){

    var result = null;
  
    if (typeof base64Image !== 'string') {
      return result;
    }
  
    var mime = base64Image.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
  
    if (mime && mime.length) {
      result = mime[1];
    }
  
    return result;
}

export const removeMimeTypeFromBase64 = function(base64Image){
    return base64Image.split(',')[1];
}