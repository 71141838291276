import React, { Component } from 'react'
import {Modal, ModalHeader, ModalBody, ModalFooter, Button} from 'reactstrap';
import { faSave, faTimes, faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class ConfirmationModal extends Component {

    render() {
        return (
            <div>
                <Modal isOpen={this.props.isModalOpen} fade={false} toggle={this.toggle}>
                    <ModalHeader toggle={this.props.toggleConfirmationModal}>Attenzione</ModalHeader>
                    <ModalBody>
                        Confermi il salvataggio del modello?
                    </ModalBody>
                    <ModalFooter>
                        { this.props.isConfigSaveInProgress ? <FontAwesomeIcon icon={faCircleNotch} spin size="2x" className="float-left"/> : "" }
                        <Button color="success" 
                            disabled={this.props.isConfigSaveInProgress ? true : false}
                            onClick={this.props.saveFormData}><FontAwesomeIcon icon={faSave} />
                            {' '}Salva
                        </Button>
                        {' '}
                        <Button color="danger" 
                            disabled={this.props.isConfigSaveInProgress ? true : false}
                            onClick={this.props.toggleConfirmationModal}><FontAwesomeIcon icon={faTimes} />
                            {' '}Chiudi
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}
