
const flags = [
    {
        id: 2,
        description : 'Buzzer Tastiera',
        value: true,
        hint: {
            IT: "Per default la tastiera è sonora. Premendo i tasti [FN] + [,] si commuta la condizione e quindi, in stato REG, alla pressione di un tasto qualsiasi viene emesso, oppure no, un segnale acustico."
        }
    },
    {
        id: 3,
        description : 'Stampa apertura cassetto ',
        value: false,
        hint: {
            IT: "Per default non si hanno stampe di scontrini in seguito all'apertura del cassetto, premendo i tasti [FN] + [,] si commuta la condizione e quindi, in stato \"REG\", all'apertura del cassetto, al di fuori di transazioni di vendita, seguirà la stampa di uno scontrino non fiscale di apertura cassetto."
        }
    },
    {
        id: 4,
        description : 'Tastiera Fisica 10 Reparti',
        value: false,
        hint: {
            IT: "Questo flag permette di abilitare il registratore telematico a gestire 10 tasti-reparto, necessita di tastiera adeguata."
        }
    },
    {
        id: 5,
        description : 'Abilitare Barcode Scanner',
        value: false,
        hint: {
            IT: ""
        }
    },
    {
        id: 8,
        description : 'Stampa numero confezioni su scontrino',
        value: false,
        hint: {
            IT: "Per default non viene stampato il numero di confezioni sullo scontrino cliente, premendo i tasti [FN] + [,] a fine scontrino verrà stampato il numero di transazioni effettuate."
        }
    },
    // {
    //     id: 12,
    //     description : 'Stampa prezzo fattura',
    //     value: false,
    //     disabled: true,
    //     hint: {
    //         IT: "Non attivo"
    //     }
    // },
    {
        id: 13,
        description : 'Obbligo richiesta subtotale prima dei pagamenti',
        value: false,
        hint: {
            IT: "Questo flag obbliga, oppure no, l'operatore a richiedere il Sub-Totale prima di premere il tasto di chiusura scontrino."
        }
    },
    {
        id: 15,
        description : 'Abilita Display Grafico Smart',
        value: true,
        hint: {
            IT: ""
        }
    },
    {
        id: 19,
        description : 'Abilitazione funzione Storno',
        value: true,
        hint: {
            IT: "Questo FLAG permette di proteggere l'utilizzo del tasto STORNO; se il valore del FLAG è \"SI\" (default) il tasto è attivabile solo accedendo preventivamente allo stato \"X\". Cambiando il valore del FLAG a \"NO\" il tasto è di nuovo attivo in stato \"REG\"."
        }
    },
    {
        id: 20,
        description : 'Abilitazione funzione Reso',
        value: true,
        hint: {
            IT: "Questo FLAG permette di proteggere l'utilizzo del tasto CORREZIONE (RETTIFICA); se il valore del FLAG è \"SI\" (default) il tasto è attivabile solo accedendo preventivamente allo stato \"X\". Cambiando il valore del FLAG a \"NO\" il tasto è di nuovo attivo in stato \"REG\"."
        }
    },
    {
        id: 21,
        description : 'Abilitazione funzione Void Totale',
        value: true,
        hint: {
            IT: "Questo FLAG permette di proteggere l'utilizzo del tasto ANNULLO; se il valore del FLAG è \"SI\" (default) il tasto è attivabile solo accedendo preventivamente allo stato \"X\". Cambiando il valore del FLAG a \"NO\" il tasto è di nuovo attivo in stato \"REG\"."
        }
    },
    {
        id: 22,
        description : 'Abilitazione funzione sconto %',
        value: true,
        hint: {
            IT: "Questo FLAG permette di proteggere l'utilizzo dei tasti %-; se il valore del FLAG è \"SI\" (default) il tasto è attivabile solo accedendo preventivamente allo stato \"X\". Cambiando il valore del FLAG a \"NO\" il tasto è di nuovo attivo in stato \"REG\"."
        }
    },
    {
        id: 23,
        description : 'Abilitazione funzione sconto valore',
        value: true,
        hint: {
            IT: "Questo FLAG permette di proteggere l'utilizzo del tasto SCONTO; se il valore del FLAG è \"SI\" (default) il tasto è attivabile solo accedendo preventivamente allo stato \"X\". Cambiando il valore del FLAG a \"NO\" il tasto è di nuovo attivo in stato \"REG\"."
        }
    },
    {
        id: 24,
        description : 'Abilitazione tasto non riscosso DCRaSSN',
        value: false,
        hint: {
            IT: ""
        }
    },
    {
        id: 25,
        description : 'Abilitazione tasto non riscosso fattura',
        value: true,
        hint: {
            IT: ""
        }
    },
    {
        id: 26,
        description : 'Abilitazione tasto omaggio',
        value: true,
        hint: {
            IT: ""
        }
    },
    {
        id: 28,
        description : 'Stampa numero cassa',
        value: false,
        hint: {
            IT: "Abilita la stampa del n° cassa a fine scontrino."
        }
    },
    {
        id: 29,
        description : 'Stampa scontrino a fine transazione',
        value: true,
        hint: {
            IT: "Quando è attivo questo flag, le transazioni di vendita vengono memorizzate senza essere stampate. La stampa dello scontrino avviene dopo il comando di chiusura (totale, credito, ecc.). Quando è disattivato, la stampa della singola riga avviene dopo la pressione del tasto REPARTO."
        }
    },
    {
        id: 30,
        description : 'Obbligo inserimento pagamento',
        value: false,
        hint: {
            IT: "Se il flag è attivo diventa obbligatorio inserire la modalità di pagamento prima di chiudere lo scontrino."
        }
    },
    {
        id: 31,
        description : 'Stampa interlinea riga totale',
        value: false,
        hint: {
            IT: "Se viene attivato il flag viene stampata una riga bianca dopo la scritta TOTALE EURO sullo scontrino. In questo modo risulta più evidenziato il totale."
        }
    },
    {
        id: 33,
        description : 'Descrizione con tastiera alfabetica',
        value: true,
        hint: {
            IT: "Abilita la tastiera alfanumerica per l’inserimento delle descrizioni (vedi paragrafo Programmazione delle descrizioni alfanumeriche)."
        }
    },
    {
        id: 34,
        description : 'Retroilluminazione',
        value: true,
        hint: {
            IT: "Premendo i tasti [FN] + [,] si attiva/disattiva lo spegnimento automatico della retroilluminazione del \
            display. Quando questo flag è attivo ed il registratore di cassa sta funzionando a batterie la \
            retroilluminazione si spegne dopo 30 secondi di inattività del registratore di cassa; alla pressione di un \
            tasto qualsiasi si riattiva." 
        }
    },
    {
        id: 35,
        description : 'Credito come corrispettivo non pagato',
        value: true,
        hint: {
            IT: "Se viene attivato questo flag, i pagamenti registrati con il tasto CREDITO vengono accumulati nel \
            TOTALE CORRISPETTIVI NON RISCOSSI (come i pagamenti con TICKET). Lasciando il valore a NO questi \
            pagamenti vengono accumulati nel TOTALE CREDITI." 
        }
    },
    {
        id: 36,
        description : 'Stampa resti in finanziario',
        value: true,
        hint: {
            IT: "Attivando il flag i resti consegnati ai Clienti (sul pagamento in contanti e/o in ticket) vengono totalizzati."
        }
    },
    {
        id: 37,
        description : 'Virgola implicita',
        value: true,
        hint: {
            IT: "Questo flag definisce se nella digitazione degli importi occorre premere la virgola o no per l’inserimento dei centesimi. <br /> \
            Con il flag settato a SI per inserire: <br> \
            1,25€ occorre premere i tasti [1] [2] [5], senza digitare la virgola. <br> \
            1,00€ occorre premere i tasti [1] [0] [0], senza digitare la virgola. <br> \
            Con il flag settata a NO per inserire: <br> \
            1,25€ occorre premere i tasti [1] [ , ] [2] [5] . <br> \
            1,00€ occorre premere il tasto [1] , senza digitare la virgola. <br> "
        }
    },
    {
        id: 38,
        description : 'Stampa numeri random fine scontrino',
        value: false,
        hint: {
            IT: "Attivando questo flag a fine scontrino viene stampata una combinazione casuale di 6 numeri compresi tra 1 e 90 (suggerimento per la giocata del lotto o del superenalotto)."
        }
    },
    {
        id: 39,
        description : 'Obbligo chiusura giornaliera',
        value: false,
        hint: {
            IT: "Attivando questo flag si abilita la chiusura obbligatoria ad un’ora prestabilita. \
            L’ora a cui deve essere eseguita la chiusura si programma poi tra le impostazioni (prog. 15). Un minuto \
            prima dell’ora programmata la cassa smette di emettere scontrini fiscali, accetta solo la chiusura \
            fiscale. Il normale funzionamento riprende solo dopo l’ora stabilita se è stata eseguita la chiusura \
            fiscale. Dopo la stampa della chiusura fiscale occorre comunque attendere 1 minuto circa prima di \
            operare sulla tastiera." 
        }
    },
    {
        id: 40,
        description : 'Visualizzazione automatica data/ora e Immagini',
        value: false,
        hint: {
            IT: "Attivando questo flag la stampante dopo alcuni minuti di inattività visualizzerà automaticamente data e ora."
        }
    },
    {
        id: 41,
        description : 'Funzione ventilazione IVA',
        value: false,
        hint: {
            IT: "Attivando questo flag l’RT opererà in regime di ventilazione IVA."
        }
    },
    {
        id: 42,
        description : 'Stampa sempre il dettaglio pagamenti',
        value: true,
        hint: {
            IT: "Attivando questo flag l’RT stamperà sul documento commerciale, dopo il logotipo, il riepilogo delle modalità di pagamento ricevute."
        }
    },
    {
        id: 43,
        description : 'Gestisci On/Off da PC',
        value: false,
        hint: {
            IT: "Quando abilitato, questo flag permette lo spegnimento dell’RT da sistema Basiq Touch."
        }
    },
    {
        id: 44,
        description : 'Stampa compatta',
        value: false,
        hint: {
            IT: "Questo flag permette la stampa compattata dello scontrino, riducendo lo spazio di interlinea è possibile ottenere scontrini più corti consumando meno carta."
        }
    },
    {
        id: 45,
        description : 'Stampa Informazioni lotteria',
        value: true,
        hint: {
            IT: "Questo flag permette di abilitare la stampa di cortesia per gli scontrini che partecipano alla lotteria e indica le informazioni su come controllare le estrazioni vincenti."
        }
    }

]

export default flags;