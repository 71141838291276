import request from "../helpers/request.js";
import localStorage from "../helpers/localStorage.js";

const host = process.env.REACT_APP_API_RT_SERVICE_HOST;
const endpoint = process.env.REACT_APP_RT_DEVICES_ENDPOINT;


export const getByFiscalNumber = (fiscalNumber) => {
	const url = `${endpoint}/get-by-fiscal-number/${fiscalNumber}`;
	return new Promise((resolve, reject) => {
		request(host, {
			method: "GET",
			url: url,
			config: {
				headers: { Authorization: `Bearer ${localStorage.getAccessToken()}` },
				withCredentials: true
			}
		})
		.then((response) => {
			resolve(response);
		})
		.catch((err) => {
			reject(err);
		});
	});
}


/**
 * Get a list of devices
 * devices list is paginated and optionally it could be sorted and filtered by devices parameters
 * @param {*} page
 * @param {*} limit
 * @param {*} search_params
 * @param {*} order_params
 */
export const getAll = (page, limit, search_params, order_params) => {
	let url =
		`${endpoint}?page=${page}&limit=${limit}&sort_by=${order_params.sort}&order_by=${order_params.name}` +
		`${search_params.fiscal_number === "" ? "" : "&fiscal_number=" + search_params.fiscal_number}` +
		//`${search_params.serial_number  === "" ? "" : "&serial_number="+search_params.serial_number}`+
		`${search_params.activity_name === "" ? "" : "&activity_name=" + search_params.activity_name}` +
		`${search_params.vat_code === "" ? "" : "&vat_code=" + search_params.vat_code}` + 
		`${search_params.mobile_search === "" ? "" : "&mobile_search=" + search_params.mobile_search}`;
	//`${search_params.created_at     === "" ? "" : "&created_at="+search_params.created_at}`;

	return new Promise((resolve, reject) => {
		request(host, {
			method: "GET",
			url: url,
			config: {
				headers: { Authorization: `Bearer ${localStorage.getAccessToken()}` },
				withCredentials: true
			}
		})
			.then((response) => {
				resolve(response);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const get = (idDevice) => {
	const url = `${endpoint}/${idDevice}`;

	return new Promise((resolve, reject) => {
		request(host, {
			method: "GET",
			url: url,
			config: {
				headers: { Authorization: `Bearer ${localStorage.getAccessToken()}` },
				withCredentials: true
			}
		})
			.then((response) => {
				resolve(response);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const updateDevice = (idDevice, isEnabledRemoteUpgrade) => {
	const url = `${endpoint}/${idDevice}`;
	const body = {
		isRemoteUpgradeEnabled: isEnabledRemoteUpgrade
	};

	return new Promise((resolve, reject) => {
		request(host, {
			method: "PATCH",
			url: url,
			body: body,
			config: {
				headers: { Authorization: `Bearer ${localStorage.getAccessToken()}` },
				withCredentials: true
			}
		})
			.then((response) => {
				resolve(response);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const getLinkReseller = (matricola) => {
	let url = `${endpoint}/${matricola}/link-to-reseller`;

	return new Promise((resolve, reject) => {
		request(host, {
			method: "GET",
			url: url,
			config: {
				headers: { Authorization: `Bearer ${localStorage.getAccessToken()}` },
				withCredentials: true
			}
		})
			.then((response) => {
				resolve(response);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const linkDeviceToConfiguration = async (matricola, configId) => {
	let url = `${endpoint}/${matricola}/link-to-config`;

	const body = {
		idConfiguration: configId
	};

	return new Promise((resolve, reject) => {
		request(host, {
			method: "PATCH",
			url: url,
			body: body,
			config: {
				headers: { Authorization: `Bearer ${localStorage.getAccessToken()}` },
				withCredentials: true
			}
		})
			.then((response) => {
				resolve(response);
			})
			.catch((err) => {
				reject(err);
			});
	});
};
