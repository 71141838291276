import React, { Component } from 'react';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class DefaultLoader extends Component {
     render() {
          return (
               <div className="text-center">
                    <p>Caricamento in corso...</p>
                    <FontAwesomeIcon icon={faCircleNotch} spin size="3x"/>
               </div>
          )
     }
}
