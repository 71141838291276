import RtList from "../view/RtList/RtList";
import Configuration from "../view/Configuration/Configuration";
import { faFileAlt, faPrint } from "@fortawesome/free-solid-svg-icons";
/**
 * Sidebar routing
 */
const sideBarRoutes = [
	{
		name: "Lista dispositivi RT",
		path: "/rt-list",
		icon: faPrint,
		component: RtList
	},
	{
		name: "Modelli",
		path: "/configuration",
		icon: faFileAlt,
		component: Configuration
	}
];

export default sideBarRoutes;
