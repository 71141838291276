const deviceHelper = {

	fromFiscalNumberToModel: function (fiscalNumber) {
		let modello = "";
		if(fiscalNumber) {
			switch(fiscalNumber.substring(0,5).toUpperCase()) {
				case "94ISM":
					modello = "smaRT";
					break;
				case "94IBQ":
					modello = "Basiq";
					break;
				default: 
					modello = "";
					break;
			}
		}
		return modello;
	},

	fromFiscalNumberToDeviceType: function (fiscalNumber) {
		const model = this.fromFiscalNumberToModel(fiscalNumber);
		return model === "Basiq" ? 1 : 2;
	}

}

export default deviceHelper;