import React, { memo } from 'react';
import { ReactComponent as LogoLabware } from './labware_logo.svg';


const Logo = () => {

    return (
        <LogoLabware/>
        // <img alt="" className="mb-3 mt-3" src='/labware_logo.svg' width="100%" height="50%"/>
    );
    
}

export default memo(Logo);