import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from "@fortawesome/free-solid-svg-icons";

export default class Navbar extends React.Component {
    
    render(){
        return (
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="container-fluid">
                    <a id="sidebarCollapse" onClick={this.props.toggleSidebar}>
                        <FontAwesomeIcon icon={faBars} size="2x" />
                    </a>
                </div>
            </nav>
        )
    }
}