import React, { Component } from 'react'
import AlertIconExpiration from './AlertIconExpiration';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSdCard, faCircle, faEye, faTrash} from '@fortawesome/free-solid-svg-icons';
import { Button, ButtonGroup, Tooltip } from 'reactstrap';
import { isMobile } from "react-device-detect";

class RtListRow extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isOpenViewTooltip : false,
            isOpenDeleteTooltip : false
        }
		// this.mobileHiddenClass = "d-none d-md-table-cell"
		this.mobileHiddenClass = ""
    }

    toggleViewTooltip = () => {

        this.setState({
            isOpenViewTooltip: !this.state.isOpenViewTooltip
        })

    }

    toggleDeleteTooltip = () => {

        this.setState({
            isOpenDeleteTooltip: !this.state.isOpenDeleteTooltip
        })

    }

    formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        // const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${day}/${month}/${year} ${hours}:${minutes}`;
    };

	rowOnClickHandler = (id, address, zip_code) => {
		if(isMobile) {
			this.props.openRtModal(id, address, zip_code)
		}
	}

    render() {

        const { id, fiscal_number, serial_number, created_at, activity_name, vat_code, fiscal_code, address, zip_code, dgfe_expiration_date, vfp_expiration_date, isEnabledRemoteUpgrade } = this.props.device;

        const viewButtonId      = 'viewButtonId_' + id;
        const deleteButtonId    = 'deleteButtonId_' + id;
        const date = new Date(created_at);
        const formattedDate = this.formatDate(date)

        return (
            <tr className="rtlist-table-row" key={id} onClick={() => this.rowOnClickHandler(id, address, zip_code)}>
                {!isMobile && <td className={"align-middle " + this.mobileHiddenClass}>
                    <AlertIconExpiration expiration_date={dgfe_expiration_date}>
                        <FontAwesomeIcon icon={faSdCard}></FontAwesomeIcon>
                    </AlertIconExpiration>{' '}
                    <AlertIconExpiration expiration_date={vfp_expiration_date}>
                        <strong>V</strong>
                    </AlertIconExpiration>
                </td>
				}
                <td className="align-middle">{fiscal_number}</td>
                <td className="align-middle">{activity_name}</td>
                <td className="align-middle">{vat_code || fiscal_code}</td>
                { !isMobile && <td className={"align-middle " + this.mobileHiddenClass}>{formattedDate}</td> }
                { !isMobile && <td></td> }
                { !isMobile && <td></td> }
                { !isMobile && <td className={"align-middle " + this.mobileHiddenClass}  style={{textAlign: 'center'}}> 
                    <FontAwesomeIcon icon={faCircle} style={{color:isEnabledRemoteUpgrade ? 'green' : 'red'}}/>
                </td> }
                { !isMobile && <td className={"align-middle " + this.mobileHiddenClass} style={{textAlign: 'center'}}>
                    <ButtonGroup>
                        <Button
                            id={viewButtonId}
                            size={isMobile ? 'lg' : 'sm'}
                            color="success"
                            onClick={() => this.props.openRtModal(id, address, zip_code)}
                            style={{marginRight: 7}}
                        >
                            <FontAwesomeIcon icon={faEye} />
                        </Button>
                        <Tooltip autohide={false} isOpen={this.state.isOpenViewTooltip} target={viewButtonId} toggle={this.toggleViewTooltip}>
                            Visualizza
                        </Tooltip>
                    </ButtonGroup>
                </td>
				}
            </tr>
        )
    }
}

export default RtListRow
