import React, { useEffect, useState } from 'react';
import { Button, FormGroup, Input, Alert } from "reactstrap";
import Logo from '../../layout/Logo/Logo';
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import localStorageHelper from '../../helpers/localStorage.js'; 
import * as AuthAPI from '../../services/authentication.js';
import { useLocation, useNavigate } from 'react-router-dom';

const Login = () => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isLoginInProgress, setIsLoginInProgress] = useState(false);
    const [isLoginFailed, setIsLoginFailed] = useState(false);
    const [currentMatricola, setCurrentMatricola ] = useState('');

    const navigate = useNavigate();
    const location = useLocation();

    const matricola = location.state?.serial ?? null;

    useEffect(() => {
        const matricola = location.state?.serial ?? '';
        setCurrentMatricola(matricola);
    },[])

    const validateForm = () =>{
        return username.length > 0 && password.length > 0;
    }

    const formSubmit = (event) => {
        setIsLoginInProgress(true);
        setIsLoginFailed(false);

        AuthAPI.login(username, password)
            .then(response => {

                if(response.status === 200 && response.data !== "") 
                {
                    localStorageHelper.setAccessToken(response.data.access_token);
                    localStorageHelper.setRefreshToken(response.data.refresh_token);
                    localStorageHelper.setIsUserLoggedIn(true);
                    localStorageHelper.setUsername(username);
                    if(currentMatricola){
                        navigate('/home', { state: { serial: currentMatricola }  });
                    }else{
                        navigate('/home');
                    }
                    
                }
                else 
                {
                    setIsLoginInProgress(false);
                    setIsLoginFailed(true);
                }
            })
            .catch((error, response) => {
                setIsLoginInProgress(false);
                setIsLoginFailed(true);
                console.log(error);
                console.log(response);
            });

        event.preventDefault();
    }


    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col col-md-6">
                    <Logo />
                    <h2>Login</h2>
                    <hr></hr>
                    <form onSubmit={(event) => formSubmit(event)}>
                        <FormGroup>
                            <Input type="text" name="username" id="username" placeholder="Username" value={username} onChange={(event) => setUsername(event.target.value)} />
                        </FormGroup>
                    
                        <FormGroup>
                            <Input type="password" name="password" id="password" placeholder="Password" value={password} onChange={(event) => setPassword(event.target.value)} />
                        </FormGroup>
                    
                        <Button block disabled={!validateForm() || isLoginInProgress} type="submit">
                            { isLoginInProgress ? 
                                <FontAwesomeIcon icon={faCircleNotch} spin /> : ""} Login
                        </Button>
                        <div className="clearfix">&nbsp;</div>
                        <Alert color="danger" isOpen={ isLoginFailed ? true : false}>Username e/o password non corretti</Alert>
                    </form>
                </div>
            </div>
        </div>
    )

}


export default Login;