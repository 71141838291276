import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from "@fortawesome/free-solid-svg-icons";

export default class Favourite extends Component 
{
     constructor(props) {
          super(props)
      
          this.state = {}
     }

     setIconColour = () => {
          if(this.props.isFavourite){
               return "goldenrod";
          }
          return "lightgrey";
     }

     toggleFavourite = () => {
          if(typeof this.props.toggleFavourite === 'function'){
               const curFavourite = this.props.isFavourite === 0 ? true : false;
               this.props.toggleFavourite(this.props.id, curFavourite);
          } 
     }

     render() {
          return (
               <FontAwesomeIcon 
                    icon={faStar} 
                    color={this.setIconColour()} 
                    style={{ cursor: 'pointer'}} 
                    onClick={this.toggleFavourite}
               />
          )
     }
}
