/**
 * Checks if a fiscalcode is valid or not
 * @param {string} fiscalCode 
 */
const checkFiscalCode = function($fiscalCode)
{
    if(typeof $fiscalCode !== "string") return false;

    let fiscalCode = $fiscalCode.toUpperCase();

    if(fiscalCode === "") return false;
    
    if( ! /^[0-9A-Z]{16}$/.test(fiscalCode) ) return false;

    var map = [1, 0, 5, 7, 9, 13, 15, 17, 19, 21, 1, 0, 5, 7, 9, 13, 15, 17, 19, 21, 2, 4, 18, 20, 11, 3, 6, 8, 12, 14, 16, 10, 22, 25, 24, 23];
    var s = 0;

    for(var i = 0; i < 15; i++){
    if(fiscalCode === "") return false;
		var c = fiscalCode.charCodeAt(i);
		if( c < 65 )
			c = c - 48;
		else
			c = c - 55;
		if( i % 2 === 0 )
			s += map[c];
		else
			s += c < 10? c : c - 10;
    }
    
    var expectedChar = String.fromCharCode(65 + s % 26);
	if( expectedChar !== fiscalCode.charAt(15) ) return false;
    
    return true;
}

export default checkFiscalCode;