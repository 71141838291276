import React, { Component } from 'react';
import {
    FormGroup,
    Label,
    Input, 
    FormFeedback,
    Row,
    Col,
    InputGroup,
    InputGroupAddon,
    Button
} from 'reactstrap';

import { faPlusSquare, faMinusSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isMobile } from "react-device-detect";

export default class TaxFormData extends Component {
    constructor(props){
        super(props);

        this.state = {
            currentTaxRows : 3,
            maxTaxRows : 4,
            validation_status : {
            },
            tax : [
                { id: 1, rate: 22.00, valid: true},
                { id: 2, rate: 10.00, valid: true},
                { id: 3, rate: 4.00, valid: true},
                { id: 4, rate: 5.00, valid: true},
            ],
            isValidForm: true,
            isFormLocked: false
        }

    }

    componentDidMount(){

        if(typeof this.props.data == 'undefined'){
            this.checkValidForm();
            return ;
        }
        
        let data = this.decodeData();
        
        this.setState({
            tax : data,
            currentTaxRows : data.length
        }, () => {
            // this is needed when going ahead and behind on wizard to automatically validate fields, 
            // otherwise it's needed to trigger event by editing input fields
            this.checkValidForm()
        });

        if(data.length === 4){
            this.setState({
                isAddButtonDisabled: true
            })
        }

        if(data.length === 1){
            this.setState({
                isRemoveButtonDisabled: true
            })
        }

    }

    componentDidUpdate(prevProps){

        if(prevProps.data === undefined && this.props.mod === 'edit' && this.props.data !== undefined){

            let data = this.decodeData();

            this.setState({
                tax : data,
                currentTaxRows : data.length
            }, () => {
                // this is needed when going ahead and behind on wizard to automatically validate fields, 
                // otherwise it's needed to trigger event by editing input fields
                this.checkValidForm()
            });

            if(data.length === 4){
                this.setState({
                    isAddButtonDisabled: true
                })
            }

            if(data.length === 1){
                this.setState({
                    isRemoveButtonDisabled: true
                })
            }
            
        }

    }


    decodeData() {

        if(this.props.mod === 'edit'){

            let tax_array = [];

            this.props.data.forEach( (tax) => {

                let obj = {
                    id : tax.id,
                    rate : tax.rate,
                    valid: true
                };

                tax_array.push(obj);

            })

            return tax_array;

        }
        else{

            let props_data = this.props.data;

            props_data.forEach((tax) => {
                tax.valid = true;

            })

            return props_data;

        }

    }

    handleAddNewTaxRow = () => {

        if(this.state.currentTaxRows === this.state.maxTaxRows - 1){
            this.setState({
                isAddButtonDisabled: true
            })
        }

        let taxState = this.state.tax;
        let newTaxRow = {
            id: this.state.currentTaxRows + 1,
            value: '',
            valid: false
        }

        taxState.push(newTaxRow);

        this.setState({
            isRemoveButtonDisabled: false,
            currentTaxRows : this.state.currentTaxRows + 1,
            tax: taxState,
            isValidForm: false

        }, () => {
            this.checkValidForm()
        });

    }

    handleRemoveTaxRow = () => {

        let currentTaxRowStatus = this.state.currentTaxRows - 1;

        if(currentTaxRowStatus < this.state.maxTaxRows ){
            this.setState({
                isAddButtonDisabled: false
            }, () => {
                this.checkValidForm()
            })
        }

        if(currentTaxRowStatus === 1){
            this.setState({
                isRemoveButtonDisabled: true
            }, () => {
                this.checkValidForm()
            })
        }

        let taxState = this.state.tax;
        taxState.pop();

        this.setState({
            tax: taxState,
            currentTaxRows : currentTaxRowStatus,
        })
    }
    

    handleTaxRateChange = (index, rate) => {

        let taxState = this.state.tax;
        taxState[index - 1].rate = rate;

        if(!isNaN(rate) && rate > 0 && rate < 100 ){
            
            taxState[index - 1].valid = true;

        }
        else{
            taxState[index - 1].valid = false;
        }

        this.setState({
            tax: taxState
        }, () => this.checkValidForm());

    }

    checkValidForm = () => {

        let valid_response = this.state.tax.every( (tax) => {

            return tax.valid ? true : false;

        })

        /* this.setState({
            isValidForm : valid_response
        }) */

        this.props.isStepValidated(valid_response, this.props.tabId);
        if(valid_response){

            this.props.saveTaxData(
                this.formatTaxData()
            );

        }

    }

    formatTaxData = () => {

        var taxes = [];

        this.state.tax.forEach((tax) => {

            taxes.push({
                id: tax.id,
                rate: Number.parseFloat(tax.rate).toFixed(2)
            })

        });

        return taxes;

    }

    /*handleSaveButton = () => {

        this.props.isStepValidated(true);
        this.props.saveTaxData(this.state.tax);
        this.setState({
            isFormLocked: true
        })

    }*/

    renderTaxRow = ({id,rate,valid}) => <React.Fragment key={id}>
                                            <Row className={`${+id %2 == 1 ? "ghostwhite mtb-20" : "mtb-20"}`}>
                                                <Col md={5}>
                                                    <div className={isMobile ? 'flex-row' : ''}>
                                                        <Label for="lab_vatcode">{`Aliquota IVA ${id}`}</Label>
                                                        <FormGroup>
                                                            <InputGroup>
                                                                <Input
                                                                    disabled={this.state.isFormLocked}
                                                                    invalid={valid ? false : true }
                                                                    type="number"
                                                                    value={this.state.tax[id-1].rate}
                                                                    onChange={ e => this.handleTaxRateChange(id, e.target.value)} />
                                                                <InputGroupAddon addonType="append">%</InputGroupAddon>
                                                                <FormFeedback>{this.state.validation_status.lab_vatcode_feedback}</FormFeedback>
                                                            </InputGroup>
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </React.Fragment>

    render() {
        return (
            <React.Fragment>
                <br />
                <h5>Modulo Aliquote IVA</h5>
                <hr></hr>
                    {this.state.tax.map(this.renderTaxRow,this)}
                    <Row>
                        <Col>
                            <Button
                                style={{float:'left', marginRight: '10px', marginTop: '5px' }}
                                color="primary"
                                outline
                                onClick={this.handleAddNewTaxRow}
                                disabled={this.state.isAddButtonDisabled}
                            >
                                <FontAwesomeIcon icon={faPlusSquare} size="lg"/> Aggiungi Riga
                            </Button>
                            {' '}
                            <Button
                                style={{marginTop: '5px' }}
                                color="danger"
                                outline
                                disabled={this.state.isRemoveButtonDisabled}
                                onClick={this.handleRemoveTaxRow}
                            >
                                <FontAwesomeIcon icon={faMinusSquare} size="lg"/> Elimina Ultima Riga
                            </Button>
                        </Col>
                    </Row>

            </React.Fragment>
        )
    }
}
