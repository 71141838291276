import React, { Component } from 'react';
import dateHelper from '../../helpers/date.js';

const EXPIRING_IN_30_DAYS = 30;
const EXPIRED = 0;

const ACTIVE_STATUS = 0;
const EXPIRING_STATUS = 1;
const EXPIRED_STATUS = 2;

export default class AlertIconExpiration extends Component {

     constructor(props) {
          super(props)
     
          this.state = {
               expiration_status: ACTIVE_STATUS,
               alert_color: ""
          }
     }

     componentDidMount() {
          this.checkExpirationStatus(this.props.expiration_date);
     }

     checkExpirationStatus(expirationDate)
     {
          let expirationTimestamp = dateHelper.getTimestampFromDate(expirationDate);
          let currentTimestamp    = new Date().getTime() / 1000;
          let remainingDays       = dateHelper.getDaysBetweenTimestamps(expirationTimestamp, currentTimestamp);
          
          if(!remainingDays) return false;

          if(remainingDays > EXPIRED && remainingDays <= EXPIRING_IN_30_DAYS){
               this.setState({ 
                    expiration_status: EXPIRING_STATUS,
                    alert_color: "yellow"
               })
          }
          else if(remainingDays <= EXPIRED){
               this.setState({ 
                    expiration_status: EXPIRED_STATUS,
                    alert_color: "red"
               })
          }
     }
     
     render() {
          return (
               <span style={{color: this.state.alert_color}}>
                    { this.state.expiration_status === ACTIVE_STATUS ? "" : this.props.children }
               </span>
          )
     }
}
