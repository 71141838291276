import React, { useState } from 'react'
import date from '../../helpers/date';
import Favourite from '../Common/Favourite/Favourite.jsx';
import { Button, ButtonGroup, Tooltip } from 'reactstrap';
import { faTrash, faCopy, faEye, faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isMobile } from "react-device-detect";

const ListRow = (props) => {

    const [isOpenEditTooltip, setIsOpenEditTooltip] = useState(false);
    const [isOpenDeleteTooltip, setIsOpenDeleteTooltip] = useState(false);
    const [isOpenDuplicateTooltip, setIsOpenDuplicateTooltip] = useState(false);

    const { timestamp, is_linked_to_devices, is_favorite, id, activity_name, business_name, vat_code, address, zip_code, code } = props.configuration;

    const editButtonId = "editButton_" + id;
    const deleteButtonId = "deleteButton_" + id;
    const duplicateButtonIn = "duplicateButton_" + id;

    const toggleEditTooltip = () => {
        setIsOpenEditTooltip(!isOpenEditTooltip)
    }

    const toggleDeleteTooltip = () => {
        setIsOpenDeleteTooltip(!isOpenDeleteTooltip);
    }

    const toggleDuplicateTooltip = () => {
        setIsOpenDuplicateTooltip(!isOpenDuplicateTooltip);
    }

    return (
        <tr key={id}>
            <td className="align-middle">{ date.fromTimestampToDate(timestamp, date.dmy, '/')  }</td>
            <td className="align-middle" style={{textDecoration: is_linked_to_devices === 1 ? "line-through" : "none"}}>{code}</td>
            <td className="align-middle"><Favourite id={id} isFavourite={is_favorite} toggleFavourite={(id, favourite) => props.toggleFavourite(id, favourite)} /></td>
            <td className="align-middle">{activity_name}</td>
            <td className="align-middle">{business_name}</td>
            <td className="align-middle">{vat_code}</td>
            <td className="align-middle">{address}</td>
            <td className="align-middle">{zip_code}</td>
            <td className="align-middle" style={{textAlign: 'center'}}>
                <ButtonGroup>
                    <Button 
                        id={editButtonId}
                        color={is_linked_to_devices === 1 ? "info" : "success"}
                        size={isMobile ? 'lg' : 'sm'}
                        onClick={() => props.toggleEditModal(id,code,false)}
                        style={{marginRight: 7}}
                    >
                        <FontAwesomeIcon icon={is_linked_to_devices === 1 ? faEye : faEdit}/>
                    </Button>
                    <Tooltip autohide={false} isOpen={isOpenEditTooltip} target={editButtonId} toggle={toggleEditTooltip}>
                        {is_linked_to_devices ? 'Visualizza' : 'Modifica' }
                    </Tooltip>
                    <Button
                        id={deleteButtonId}
                        disabled={is_linked_to_devices === 1 ? true : false}
                        color="danger"
                        size={isMobile ? 'lg' : 'sm'}
                        onClick={() => props.toggleDeleteModal(id)}
                        style={{marginRight: 7}}
                    >
                        <FontAwesomeIcon icon={faTrash} />
                    </Button>
                    <Tooltip autohide={false} isOpen={isOpenDeleteTooltip} target={deleteButtonId} toggle={toggleDeleteTooltip}>
                        Rimuovi
                    </Tooltip>
                    <Button
                        id={duplicateButtonIn}
                        color="secondary"
                        size={isMobile ? 'lg' : 'sm'}
                        onClick={() => props.toggleEditModal(id, code, true)}
                    >
                        <FontAwesomeIcon icon={faCopy} />
                    </Button>
                    <Tooltip autohide={false} isOpen={isOpenDuplicateTooltip} target={duplicateButtonIn} toggle={toggleDuplicateTooltip}>
                        Duplica
                    </Tooltip>
                </ButtonGroup>
            </td>
        </tr>
    )
    
}

export default ListRow
