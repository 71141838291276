import React, { Component } from 'react';
import { Alert } from 'reactstrap';

export default class AlertDanger extends Component 
{
     render() {
          return (
               <Alert color="danger">
                    [Errore: {this.props.errorCode}] { this.props.errorMessage || "Siamo spiacenti, si è verificato un errore. Contattare l'amministratore del sistema" }
               </Alert> 
          )
     }
}
