/**
 *
 */
const dateHelper = {
	ymd: "ymd",
	ymd_his: "ymd_his",
	dmy: "dmy",
	minus: "-",
	colon: ":",
	locale: "it",

	/**
	 *
	 * @param {*} timestamp
	 */
	fromTimestampToDate: function (timestamp, _format, _dateSeparator, _timeSeparator) {
		let dateSep = _dateSeparator || this.minus;
		let timeSep = _timeSeparator || this.colon;
		let format = _format || this.ymd;
		let formattedDate;

		try {
			if (isNaN(timestamp) || typeof timestamp === "undefined" || typeof timestamp === "boolean" || timestamp === "" || timestamp === null) {
				throw new Error("value: '" + timestamp + "' is an invalid timestamp");
			}
			if (timestamp < 0) {
				throw new Error("timestamp should be greather or equal than 0");
			}

			let date = new Date(timestamp * 1000);

			switch (format) {
				case this.ymd:
					formattedDate = date.getFullYear() + dateSep + this.pad(date.getMonth() + 1) + dateSep + this.pad(date.getDate());
					break;

				case this.ymd_his:
					formattedDate =
						date.getFullYear() +
						dateSep +
						this.pad(date.getMonth() + 1) +
						dateSep +
						this.pad(date.getDate()) +
						" " +
						this.pad(date.getHours()) +
						timeSep +
						this.pad(date.getMinutes()) +
						timeSep +
						this.pad(date.getSeconds());
					break;

				case this.dmy:
					formattedDate = this.pad(date.getDate()) + dateSep + this.pad(date.getMonth() + 1) + dateSep + (date.getFullYear() + "").slice(-2);
					break;

				default:
					formattedDate = date.getFullYear() + dateSep + this.pad(date.getMonth() + 1) + dateSep + this.pad(date.getDate());
					break;
			}

			return formattedDate;
		} catch (err) {
			console.error(err.name + ": " + err.message);
			return "";
		}
	},

	pad: function (integer) {
		return String("0" + integer).slice(-2);
	},

	/**
	 * get days interval from two timestamps
	 */
	getDaysBetweenTimestamps: function (startTimestamp, endTimestamp) {
		if (startTimestamp === 0 || startTimestamp === null || typeof startTimestamp === "undefined" || startTimestamp === "") return false;
		if (endTimestamp === 0 || endTimestamp === null || typeof endTimestamp === "undefined" || endTimestamp === "") return false;

		let startDate = new Date(startTimestamp).getTime() > 0;
		let endDate = new Date(endTimestamp).getTime() > 0;

		if (!startDate || !endDate) return false;

		let difference = endTimestamp - startTimestamp;
		let daysDifference = difference / 86400;

		if (daysDifference >= 0) {
			return Math.floor(difference / 86400);
		} else {
			let roundedDifference = Math.ceil(difference / 86400);
			return roundedDifference === -0 ? 0 : roundedDifference;
		}
	},

	/**
	 * Get timestamp from date
	 * Date is intended to be utc for the sake of simplicity
	 * @param {*} date
	 */
	getTimestampFromDate: function (date) {
		let timestamp = new Date(date).getTime();

		if (!timestamp) return false;

		return timestamp / 1000;
	},

	fromDatetimeToDate: function (date, options = { year: "numeric", month: "2-digit", day: "2-digit" }) {
		let formatted = date;
		if(date !== "") {
			let timestamp = new Date(date).getTime()
			formatted = new Intl.DateTimeFormat(this.locale, options).format(timestamp)
		}
		return formatted
	}
};

export default dateHelper;
