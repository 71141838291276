import React from 'react'
import {Modal, ModalHeader, ModalBody, ModalFooter, Button} from 'reactstrap';

const GenericConfirmModal = (props) => {

    return (
        <div>
            <Modal isOpen={props.isModalOpen} fade={false} >
                <ModalHeader>Attenzione</ModalHeader>
                <ModalBody>
                    {props.msg}
                </ModalBody>
                <ModalFooter>
                    <Button color="success" 
                        onClick={props.onConfirm}>
                        {' '}Conferma
                    </Button>
                    {' '}
                    <Button color="danger" 
                        onClick={props.onDismiss}>
                        {' '}Annulla
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    )

}

export default GenericConfirmModal;
