import { faArrowLeft, faArrowRight, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { Button, Col, Row } from "reactstrap";

export default class NavigationButtons extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             
        }
    }
    
    render() {
        return (
            <React.Fragment>
				<Row>
					<Col lg={12}>
						<Button outline color="secondary" hidden={this.props.wizardLevel === this.props.wizardLowestLevel ? true : false }
							onClick={this.props.decrementWizardLevel}>
							<FontAwesomeIcon icon={faArrowLeft} /> Indietro
						</Button>
						<Button 
							outline color="secondary" className="float-right" 
							hidden={this.props.wizardLevel === this.props.wizardHighestLevel ? true : false}
							disabled={!this.props.isStepValidated || this.props.wizardLevel === this.props.wizardHighestLevel ? true : false }
							onClick={this.props.incrementWizardLevel}>
							Avanti <FontAwesomeIcon icon={faArrowRight} />
						</Button>
						<Button color="success" className="float-right" size={this.props.saveButtonSize || "md" } onClick={this.props.toggleConfirmationModal}
							hidden={this.props.wizardLevel === this.props.wizardHighestLevel ? false : true }
							disabled={this.props.isFormValid ? false : true}>
							<FontAwesomeIcon icon={faSave} /> Salva
						</Button>
					</Col>
				</Row>
            </React.Fragment>
        )
    }
}
