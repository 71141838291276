import React, { useEffect, useState } from "react";
import localStorageHelper from "../../helpers/localStorage.js";

import { useLocation, useNavigate } from "react-router-dom";

const Root = () => {

	const [isLoginInProgress, setIsLoginInProgress] = useState('');
	const [isLoginFailed, setIsLoginFailed] = useState('');

	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		setIsLoginInProgress(true);
		setIsLoginFailed(false);
		
		const search = location.search;
		const searchParams = new URLSearchParams(search);
		if(searchParams.size > 0){
			const matricola = searchParams.get('m') ?? null;
			if(localStorageHelper.getIsUserLoggedIn() && matricola !== null){
				console.log('navigate with state');
				navigate("/home", { state: { serial: matricola } });
			}else if(matricola !== null){
				navigate("/login", {state:{ serial: matricola}});
			}else{
				navigate("/login", { replace: true });
			}
		}else{
			if (localStorageHelper.getIsUserLoggedIn()) {
				navigate("/home", { replace: true });
			}else {
				// Bad request
				// throw new Error("Bad request");
				navigate("/login", { replace: true });
				// window.location.replace("/login");
			}
		}	
	},[]);

	return (
		<></>
	);
	
}


export default Root;