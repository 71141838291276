import queryString from "querystring";
import axios from "axios";
import  * as UsersAPI from '../services/users';

const host =          process.env.REACT_APP_API_AUTH_HOST;
const endpoint =      process.env.REACT_APP_API_AUTH_LOGIN_URL;
const client_id =     process.env.REACT_APP_API_AUTH_CLIENT_ID;
const client_secret = process.env.REACT_APP_API_AUTH_CLIENT_SECRET;
const grant_type =    process.env.REACT_APP_API_AUTH_LOGIN_GRANT_TYPE;

export const login = async(username, password) => 
{
	return axios.post(host + "/" + endpoint, queryString.stringify({
		username: username,
		password: password,
		client_id: client_id,
		client_secret: client_secret,
		grant_type: grant_type
	}))
		.then(async response => {
		// Getting user ID from username (passing throught oauth endpoints)
		const authUserInfo = await getLoggedUserId(username);
		const id = authUserInfo.id;
		const token = response.data.access_token
		// Checking if user exists in userReseller (passing throught RT backend) searching by ID
		let ok = await UsersAPI.getUserById(id, token);
		return response;
	})
	.catch(error => {
		throw error;
	});
};


const getLoggedUserId = (username) => 
{
	const pattern = new RegExp('=.$', 'g')
	// const pattern = /=.$/g;
	const encodedUsername = btoa(username).replace(pattern, "");
	const url = host + "/users_coded/" + encodedUsername

	return new Promise((resolve, reject) => {
		axios.get(url)
			.then(response => {
				// console.log("responseCheckuser", response);
				if (response.data.data) {
					resolve(response.data.data)
				}
			})
			.catch(error => {
				// console.log("errorCheckuser", error);
				reject(error);
			});
	});
}