import axios from "axios";
import qs from "qs";
import localStorageHelper from "./localStorage.js";
// import customHistory from "../history.js";

/**
 * Retrieve a new token from a refresh_token
 */
function refreshToken() {
	let refresh_token = localStorageHelper.getRefreshToken();
	if (!refresh_token) logout();

	let refresh_token_url = `${process.env.REACT_APP_API_AUTH_HOST}${process.env.REACT_APP_API_AUTH_REFRESH}`;
	let body = {
		refresh_token: refresh_token,
		grant_type: "refresh_token",
		client_id: process.env.REACT_APP_API_AUTH_CLIENT_ID,
		client_secret: process.env.REACT_APP_API_AUTH_CLIENT_SECRET
	};

	/*let config = {
        withCredentials: true
    };*/

	return axios
		.post(
			refresh_token_url,
			qs.stringify(body) //,
			//config
		)
		.then((response) => {
			localStorageHelper.setAccessToken(response.data.access_token);
			localStorageHelper.setIsUserLoggedIn(true);
			localStorageHelper.removeRefreshToken();
		})
		.catch((err) => {
			logout();
		});
}

function logout() {
	localStorage.clear();
	window.location.reload();
}

/**
 * @description wrapper for making ajax requests
 * @param {object} object with method,url,data etc.
 */
const request = function (host, options) {
	/**
	 * axios instance for given host
	 */
	var client = axios.create({
		baseURL: host,
		withCredentials: true
	});

	/**
	 * Add static function to execute on 401 response
	 */
	client.interceptors.response.use(undefined, (err) => {
		const error = err.response;
		// Refresh token only when response code is 401
		if (error?.status === 401) {
			return refreshToken().then(() => {
				err.config.headers.Authorization = `Bearer ${localStorageHelper.getAccessToken()}`;
				return axios.request(err.config);
			});
		}

		return Promise.reject(error);
	});

	const onSuccess = function (response) {
		return response;
	};
	const onError = function (error) {
		if (error.response) {
		} else {
			//console.error('Error Message:', error.message);
		}
		return Promise.reject(error);
	};

	//TODO: SWITCH ON METHODS

	if (options.method === "GET") {
		return client.get(options.url, options.config).then(onSuccess).catch(onError);
	} else if (options.method === "POST") {
		return client.post(options.url, options.body, options.config).then(onSuccess).catch(onError);
	} else if (options.method === "DELETE") {
		return client.delete(options.url, options.config).then(onSuccess).catch(onError);
	} else if (options.method === "PUT") {
		return client.put(options.url, options.body, options.config).then(onSuccess).catch(onError);
	} else if (options.method === "PATCH") {
		return client.patch(options.url, options.body, options.config).then(onSuccess).catch(onError);
	}
};

export default request;
