import React, { Component } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
	Col,
	Label,
	Row,
	Table
} from 'reactstrap';

import { faCheck, faCog, faSpinner, faTimes, faToggleOff, faToggleOn } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isMobile } from "react-device-detect";
import dateHelper from '../../../helpers/date';
import deviceHelper from '../../../helpers/device';
import Divider from '../../Common/Divider/Divider';

export class TabDetails extends Component {
    constructor(props){
        super(props);

        this.state = {

        }

		this.currentRTStatusMap = [
			{key: 10, text: "Non censito"},
			{key: 20, text: "Censito"},
			{key: 30, text: "Attivato"},
			{key: 40, text: "Revocato"},
			{key: 50, text: "In servizio"},
			{key: 60, text: "Fuori servizio"},
		];

		this.lastVFPResultMap = [
			{key: 3, text: "Positivo"},
			{key: 4, text: "Negativo"},
			{key: 5, text: "Apparecchio ritirato"}
		];
    }

    handleRemoteUpgradeSwitch = () => {

        if(!this.props.isUpdateInProgress) this.props.handleUpdate();

    }


	decodeCurrentRTStatus = (value) => {
		let output = "";
		if(value) {
			const found = this.currentRTStatusMap.find(obj => { return obj.key === value });
			output = found.text;
		}
		return output;
	}

	decodeLastVFPResult = (value) => {
		let output = "";
		if(value) {
			const found = this.lastVFPResultMap.find(obj => { return obj.key === value });
			output = found.text;
		}
		return output;
	}

	convertMBToPercentage = (value, max) => {
		let output = "";
		if(value) {
			output = (( value / max ) * 100).toFixed(2);
		}
		return output;
	}

    render() {

        const isRemoteUpgradeEnabled    = this.props.isEnabledRemoteUpgrade;
        const isUpdateInProgress        = this.props.isUpdateInProgress;
		const device                    = this.props.device;

		const censitoIcon = device.status?.censitoSperimentazione || '' 
								? <FontAwesomeIcon icon={faCheck} size="2x"  style={{color: "green" }} />
								: <FontAwesomeIcon icon={faTimes} size="2x"  style={{color: "red" }} />

		const currentRTStatus = this.decodeCurrentRTStatus(device.status?.currentRTStatus || null);

		const lastVFPResult = this.decodeLastVFPResult(device.status?.lastVFPResult || null);

		const memoryUsed = device.dgfe[0]?.usedMemory ? this.convertMBToPercentage(device.dgfe[0]?.usedMemory, device.dgfe[0]?.totalMemory) + " %" : "";

		const modello = deviceHelper.fromFiscalNumberToModel(device.fiscal_number);

        return (
            <React.Fragment>
                <Divider>Informazioni dispositivo fiscale</Divider>
                <Row className="mt-3">
                    <Col md={6}>
						<div className={isMobile ? "flex-row" : ""}>
                        	<Label>Matricola Fiscale </Label>
							<div>{this.props.fiscal_number} </div>
						</div>
                    </Col>
                    <Col md={6}>
						<div className={isMobile ? "flex-row" : ""}>
							<Label>Configurazione </Label>
							<div>{modello}</div>
						</div>
                    </Col>
                </Row>
                <br/>
                <Row>
                    {/* <Col md={6}>
                        <Label>Numero seriale</Label>
                        <Input value={this.props.serial_number || ''} disabled/>
                    </Col> */}
                    <Col md={6}>
						<div className={isMobile ? "flex-row" : ""}>
							<Label>Installata il</Label>
							<div>{dateHelper.fromDatetimeToDate(this.props.created_at || '')} </div>
						</div>
                    </Col>
					<Col md={6}>
						<div className={isMobile ? "flex-row" : ""}>
							<Label>Versione Firmware</Label>
							<div>{device.status?.firmwareVersion || ''} </div>
						</div>
					</Col>
                </Row>
				<br/>
				{/* <Row>
					<Col md={6}>
						<Label>Conteggio tagli</Label>
						<Input value={device.status?.cutCount || ''} disabled/>
					</Col>
					<Col md={6}>
						<Label>Metri stampati</Label>
						<Input value={device.status?.printedPaperLenMeters || ''} disabled/>
					</Col>
				</Row>
				<br/> */}
				<Row>
					<Col md={6}>
						<div className={isMobile ? "flex-row" : ""}>
							<Label>Data attivazione fiscale</Label>
							<div>{dateHelper.fromDatetimeToDate(device.status?.fiscalActivationInfo_Date || '')} </div>
						</div>
					</Col>
					<Col md={6}>
						<div className={isMobile ? "flex-row" : ""}>
							<Label>Partita iva attivazione fiscale</Label>
							<div>{device.status?.fiscalActivationInfo_VATCode || ''} </div>
						</div>
					</Col>
				</Row>
				{/* <br/>
				<Row>
					<Col md={6}>
						<Label>Indirizzo MAC</Label>
						<Input value={device.status?.macAddress || ''} disabled/>
					</Col> 

				</Row> */}
				<br/>
				<Row>
					{/* <Col md={6}>
						<Label>ID blocco rivenditore</Label>
						<Input value={device.status?.resellerBlockId || ''} disabled/>
					</Col> */}
					<Col md={6}>
						<div className={isMobile ? "flex-row" : ""}>
							<Label>Stato RT attuale</Label>
							<div>{currentRTStatus}</div>
						</div>
					</Col>
					{/* 
					<Col md={6}>
						<Label>Numero ripristini fiscali</Label>
						<Input value={device.status?.fiscalResetCount || ''} disabled/>
					</Col>
					*/ }
				</Row>
				{/* 
				<br/>
				<Divider>Certificato</Divider>
				
				<Row>
					<Col md={4}>
						<Label>Id certificato</Label>
						<Input value={device.status?.certificateId || ''} disabled/>
					</Col>
					<Col md={6}>
						<Label>Certificato valido dal</Label>
						<Input value={dateHelper.fromDatetimeToDate(device.status?.certificateValidFrom || '')} disabled/>
					</Col>
					<Col md={6}>
						<Label>Scadenza certificato</Label>
						<Input value={dateHelper.fromDatetimeToDate(device.status?.certificateExpiresAt || '')} disabled/>
					</Col>
				</Row>
				<Divider>Chiusura giornaliera</Divider>
				<Row>
					<Col md={4}>
						<Label>Data ultima chiusura giornaliera</Label>
						<Input value={dateHelper.fromDatetimeToDate(device.status?.dailyClosureDate || '')} disabled/>
					</Col>
					<Col md={4}>
						<Label>Numero chiusura giornaliera</Label>
						<Input value={device.status?.dailyClosureCount || ''} disabled/>
					</Col>
					<Col md={4}>
						<Label>Residuo chiusure giornaliere</Label>
						<Input value={device.status?.dailyClosureResiduals || ''} disabled/>
					</Col>
				</Row>
				<Divider>Info DGFE</Divider>
				<Row>
					<Col md={4}>
						<Label>UID</Label>
						<Input value={device.dgfe[0]?.uid || ''} disabled/>
					</Col>
					<Col md={4}>
						<Label>Memoria totale</Label>
						<Input value={device.dgfe[0]?.totalMemory || ''} disabled/>
					</Col> 
					<Col md={6}>
						<Label>Memoria utilizzata</Label>
						<Input value={memoryUsed} disabled/>
					</Col>
					<Col md={6}>
						<Label>Data Installazione</Label>
						<Input value={dateHelper.fromDatetimeToDate(device.dgfe[0]?.installedOn || '')} disabled/>
					</Col>
					<Col md={6}>
						<Label>Data Scadenza</Label>
						<Input value={dateHelper.fromDatetimeToDate(device.dgfe[0]?.expiringOn || '')} disabled/>
					</Col>
					<Col md={6}>
						<Label>Conteggio DGFE archiviati</Label>
						<Input value={device.status?.archivedDgfeCount || ''} disabled/>
					</Col>
				</Row>
				<br/>
				<Divider>Info Verifica Periodica</Divider>
				<Row>
					<Col md={3}>
						<Label>Ultimo Codice Fiscale con cui è stata eseguita la VFP</Label>
						<Input value={device.status?.lastVFP_CF || ''} disabled/>
					</Col>
					<Col md={3}>
						<Label>Ultima P.IVA con cui è stata eseguita la VFP</Label>
						<Input value={device.status?.lastVFP_PIVA || ''} disabled/>
					</Col>
					<Col md={3}>
						<Label>Esito VFP</Label>
						<Input value={lastVFPResult} disabled/>
					</Col>
					<Col md={3}>
						<Label>Data ultima VFP</Label>
						<Input value={dateHelper.fromDatetimeToDate(device.status?.lastVFPDate || '')} disabled/>
					</Col>
				</Row>

				*/}

				{device.status?.censitoSperimentazione === true &&
					<>
					<Divider></Divider>
					<Row>
						<Col md={12}>
							<Label>Censito sperimentazione</Label>
						</Col>
					</Row> 
					</>
				}

				<Divider></Divider>

                <Row className="mt-3">

                    <Col md={6}>

                        <Card className="mt-3">
                            <CardHeader>

                                <FontAwesomeIcon icon={faCog} ></FontAwesomeIcon> Impostazioni Dispositivo

                            </CardHeader>
                            <CardBody>

                                <Table borderless >
                                    <tbody>
                                        <tr>
                                            <td style={{verticalAlign: 'middle'}}>
                                                Abilitazione Upgrade Remoto
                                            </td>
                                            <td>
                                                { isUpdateInProgress ? 
                                                
                                                <FontAwesomeIcon icon={faSpinner} spin size="3x"/> : 

                                                <FontAwesomeIcon 
                                                    icon={isRemoteUpgradeEnabled ? faToggleOn : faToggleOff} 
                                                    size="3x" 
                                                    style={{color: isRemoteUpgradeEnabled ? 'green' : 'black'}} 
                                                    onClick={this.handleRemoteUpgradeSwitch}
                                                />

                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                                
                                <br/>

                            </CardBody>
                        </Card>

                    </Col>

                </Row>

            </React.Fragment>
        )
    }
}

export default TabDetails
