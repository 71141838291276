import React, { Component } from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

export default class TablePagination extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      maxDeptFromCurrentPage: 2
    }

  }
  
  renderPreviousPaginationItems = () =>
  {
    let currentPage = parseInt(this.props.currentPage || 1);
    let lastPreviousPage = currentPage - this.state.maxDeptFromCurrentPage;
    let previousPages = [];
    let firstPreviousPage = currentPage - 1;

    for(let i = firstPreviousPage; i >= lastPreviousPage; i--)
    {
      if(i >= 1){
        let page = 
          <PaginationItem key={i}>
            <PaginationLink onClick={() => this.props.changePage(i)}>{i}</PaginationLink>
          </PaginationItem> 
        previousPages.push(page);
      }
    }
    return previousPages.reverse();
  }

  renderNextPaginationItems = () =>
  {
    let totalPages = parseInt(this.props.totalPages);
    let currentPage = parseInt(this.props.currentPage || 1);
    let lastNextPage = currentPage + this.state.maxDeptFromCurrentPage;
    let firstNextPage = currentPage + 1;
    let nextPages = [];

    for(let i = firstNextPage; i <= lastNextPage; i++)
    {
      if(i <= totalPages){
        let page = 
          <PaginationItem key={i} disabled={this.props.disabled}>
            <PaginationLink onClick={() => this.props.changePage(i)}>{i}</PaginationLink>
          </PaginationItem> 
        nextPages.push(page);
      }
    }
    return nextPages;
  }

  renderPaginationItems = () => {
    let currentPage   = this.props.currentPage || 1; 
    
    return (
    <React.Fragment>
      { this.renderPreviousPaginationItems() }
      <PaginationItem active>
        <PaginationLink>{currentPage}</PaginationLink>
      </PaginationItem>
      { this.renderNextPaginationItems() }
    </React.Fragment>
    );
  }

  renderPreviousArrows = () => {
    
    return (
      <React.Fragment>
        <PaginationItem id="pagination-first-page-arrow" disabled={parseInt(this.props.currentPage) === 1 || this.props.disabled ? true : false}>
          <PaginationLink first onClick={() => this.props.changePage(1)}/>
        </PaginationItem> 
        <PaginationItem id="pagination-previous-page-arrow" disabled={parseInt(this.props.currentPage) === 1 || this.props.disabled ? true : false} >
          <PaginationLink previous onClick={() => this.props.changePage(parseInt(this.props.currentPage) - 1)}/>
        </PaginationItem> 
      </React.Fragment>
    );
  }

  renderNextArrows = () => {
    return (
      <React.Fragment>
        <PaginationItem id="pagination-next-page-arrow" disabled={parseInt(this.props.currentPage) === parseInt(this.props.totalPages) || this.props.disabled ? true : false}>
          <PaginationLink next onClick={() => this.props.changePage(parseInt(this.props.currentPage) + 1)} />
        </PaginationItem> 
        <PaginationItem id="pagination-last-page-arrow" disabled={parseInt(this.props.currentPage) === parseInt(this.props.totalPages) || this.props.disabled ? true : false}>
          <PaginationLink last onClick={() => this.props.changePage(this.props.totalPages)} />
        </PaginationItem> 
      </React.Fragment>
    );
  }

  getCurrentPageFirstItem = () => {
    let firstItem = this.props.recordCount === 0 ? 0 : (this.props.currentPage * this.props.pageSize) - this.props.pageSize + 1;
    return firstItem;
  }

  getCurrentPageLastItem = () => {
    let lastItem = this.props.pageSize * this.props.currentPage;
    if(lastItem > this.props.recordCount){
      return this.props.recordCount;
    }
    return lastItem;
  }

  render() {
    if(typeof this.props.changePage !== "undefined"){
      return (
        <React.Fragment>
          <div id="pagination-item-view" className="float-left">
            Vista da <span>{ this.getCurrentPageFirstItem() }</span> a <span>{ this.getCurrentPageLastItem() }</span> di <span>{this.props.recordCount}</span> elementi
          </div>
          <Pagination id="pagination-pages-list" className="float-right" aria-label="Page navigation example">
          { this.renderPreviousArrows() }
          { this.renderPaginationItems() }
          { this.renderNextArrows() }
          </Pagination>
        </React.Fragment>
      );
    }
    else return null;
  }
}